import { Alert, Form, Spin, Switch } from "antd"
import axios from "axios"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { AuthContext } from "../auth-context"
import * as S from "./styled"

const url =
  (process.env.REACT_APP_ENDPOINT || "http://localhost:4000") +
  "/settings/brokers-set-consolidated"

type BrokerConsolidatedData = Record<string, boolean>
type BrokerConsolidatedAPI = {
  data: {
    brokerName: string
    consolidated: boolean
  }[]
  lastUpdated: number,
}


const SettingsBrokersSetConsolidatedCard = () => {
  const [form] = Form.useForm()
  const formRef = useRef(null)
  const { getCurrentUser } = useContext(AuthContext)
  const [brokersList, setBrokersList] = useState<string[]>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [allData, setAllData] = useState<BrokerConsolidatedData>()
  const [lastUpdated, setLastUpdated] = useState<string>()
  const [responseError, setResponseError] = useState<string>("")
  const [responseSuccess, setResponseSuccess] = useState<boolean>(false)

  const getEnableBrokersData = useCallback(
    () =>
      getCurrentUser()
        .then(user => {
          if (!user.isLogged) return
          axios
            .get<BrokerConsolidatedAPI>(url, {
              headers: {
                Authorization: user.tokens.token,
              },
            })
            .then(res => {
              const {data, lastUpdated} = res.data
              const reshaped = data.reduce(
                (acc, val) => ({
                  ...acc,
                  [val.brokerName]: val.consolidated,
                }),
                {},
              ) as BrokerConsolidatedData
              const newBrokers = data.map(({ brokerName }) => brokerName)
              
              const lastUpdatedString = new Date(lastUpdated)
                .toLocaleString("sv-SE")
              setLastUpdated(lastUpdatedString)
              setBrokersList(newBrokers)
              setAllData(reshaped)
              setIsLoading(false)
            })
            .catch(err => {
              console.error(err)
              setIsLoading(false)
              if (err.response.data) setResponseError(err.response.data)
              if (err.errorMessage) setResponseError(err.errorMessage)
            })
        })
        .catch(err => {
          console.error(err)
          setIsLoading(false)
          setResponseError(err)
        })
        .finally(() => {
          setTimeout(() => {
            setResponseSuccess(false)
          }, 5_000)
        }),
    [getCurrentUser],
  )

  useEffect(() => {
    setIsLoading(true)
    getEnableBrokersData()
  }, [getEnableBrokersData])

  const onFinish = async (values: any) => {
    if (!brokersList) return

    const outData = brokersList.map(b => ({
      broker: b,
      consolidated: values[b]
    }))

    const user = await getCurrentUser()
    if (!user.isLogged) return
    await axios
      .post<BrokerConsolidatedAPI>(
        url,
        {
          data: outData,
        },
        {
          headers: {
            Authorization: user.tokens.token,
          },
        },
      )
      .then(res => {
        setResponseSuccess(true)
        if (res.data) {
          const {data, lastUpdated} = res.data
          const reshaped = data.reduce(
            (acc, val) => ({
              ...acc,
              [val.brokerName]: val.consolidated,
            }),
            {},
          ) as BrokerConsolidatedData

          const newBrokers = data.map(({ brokerName }) => brokerName)
          
          const lastUpdatedString = new Date(lastUpdated).toLocaleString("sv-SE")
          setLastUpdated(lastUpdatedString)
          setBrokersList(newBrokers)
          setAllData(reshaped)
        } else throw new Error("Something went wrong")
      })
      .catch(err => {
        console.error(err)
        setIsLoading(false)
        if (err.response.data) setResponseError(err.response.data)
        else if (err.response.statusText)
          setResponseError(err.response.statusText)
        else if (err.errorMessage) setResponseError(err.errorMessage)
      })
      .finally(() => {
        setTimeout(() => {
          setResponseSuccess(false)
        }, 5_000)
      })
  }

  return (
    <S.Wrapper isLoading={isLoading}>
      {isLoading ? (
        <S.SpinnerWrapper>
          <Spin />
        </S.SpinnerWrapper>
      ) : (
        <div className="immovable">
          <S.TitleWrapper>
            <S.Title>Set Consolidated Brokers</S.Title>
          </S.TitleWrapper>
          <S.FormScrollable>
            <S.FormStyled
              name="enableBrokersForm"
              layout={"horizontal"}
              onFinish={onFinish}
              form={form}
              ref={formRef}
              requiredMark={false}
              initialValues={allData}
            >
              <S.Grid>
                <S.GridHeader key="firstheader">
                  <S.HeaderLabel key={`PopBroker-header`}>Broker</S.HeaderLabel>
                  <S.HeaderLabel key={`enable-broker-header-fspotter`}>
                    Consolidated
                  </S.HeaderLabel>
                </S.GridHeader>

                {brokersList &&
                  brokersList?.length > 0 &&
                  brokersList.map((broker, index) => (
                    <S.GridRow key={index}>
                      <S.GridCell key={`${index}-row`} title={broker}>
                        {broker}
                      </S.GridCell>
                      <S.GridCell key={`${index}-enable-fspotter`}>
                        <S.FormItemStyled
                          valuePropName="checked"
                          name={broker}
                          label={null}
                        >
                          <Switch />
                        </S.FormItemStyled>
                      </S.GridCell>
                    </S.GridRow>
                  )
                  )}
              </S.Grid>

              <S.FormItemStyled {...{ wrapperCol: { span: 24, offset: 0 } }}>
                <S.ButtonStyled type="primary" htmlType="submit">
                  Submit
                </S.ButtonStyled>
              </S.FormItemStyled>
            </S.FormStyled>
            {lastUpdated !== undefined && <S.BottomText>Last Update: {lastUpdated}</S.BottomText>}
          </S.FormScrollable>
          {responseSuccess ? (
            <Alert message="Data successfully updated" type="success" />
          ) : null}
          {responseError ? (
            <Alert message={responseError} type="error" />
          ) : null}
        </div>
      )}
    </S.Wrapper>
  )
}

export default SettingsBrokersSetConsolidatedCard

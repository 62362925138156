import styled from "styled-components"
import { colors, palette } from "../../styles"
import * as T from "../typography"

export const CardWrapper = styled.div<{ isLoading?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: ${colors.iUltraDarkGrey};
  width: 100%;
  min-width: 350px;
  max-width: 520px;
  height: 100%;
  padding: 16px;
  ${({ isLoading }) =>
    isLoading
      ? `display: flex;
      align-items: center;
      justify-content: center;`
      : ``}
  @media (max-width: 599px) {
    min-width: 250px;
  }
`

export const SpinnerWrapper = styled.div`
  .ant-spin .ant-spin-dot-holder {
    color: ${palette.primary};
  }
`

export const CardTitleWrapper = styled.div`
  text-align: center;
  width: 100%;
  padding-bottom: 8px;
  margin-bottom: 9px;
  border-bottom: 1px solid ${colors.iMediumGrey};
`

export const CardTitle = styled(T.H3)``

export const WebFxContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 2fr 2fr 2fr;
  width: 100%;
  row-gap: 5px;
  margin-bottom: 1.5em;
`

export const HeaderLabel = styled(T.H4)<{
  left?: boolean
  center?: boolean
  right?: boolean
}>`
  color: ${colors.iWhite};
  text-align: center;
  ${({ left }) => left && "text-align: left;"}
  ${({ center }) => center && "text-align: center;"};
  ${({ right }) => right && "text-align: right;"};

  margin: 0 0.2em 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Text = styled(T.P3)<{
  color?: string
  left?: boolean
  center?: boolean
  right?: boolean
}>`
  color: ${({ color }) => color};
  ${({ left }) => left && "text-align: left;"}
  ${({ center }) => center && "text-align: center;"};
  ${({ right }) => right && "text-align: right;"};
  padding: 0.3em;
`

export const Row = styled.div`
  display: contents;

  :not(.header):hover > * {
    background-color: ${colors.iDarkGrey};
    transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`

export const BottomText = styled(T.P3)`
  color: ${colors.iMediumGrey};
  text-align: right;
  width: 100%;
  margin-top: 1em;
`

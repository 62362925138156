import useResizeObserver from "@react-hook/resize-observer"
import axios from "axios"
import HighchartsReact from "highcharts-react-official"
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { z } from "zod"
import {
  getBrokerPair,
  getPairFromId,
  getSerieGraphData,
  getWeekStart,
  mergeNewData,
  zip,
} from "../../helpers"
import indexDb from "../../helpers/indexDB"
import { breakpointNum } from "../../styles"
import { GraphInfo, GraphReactComponent, PepTick } from "../../types"
import { AuthContext } from "../auth-context"
import PEPSingleMemoizedGraph from "../pep-single-memoized-graph"
import WidgetHeader from "../widget-header"
import { WidgetSubheader } from "../widget-subheader"
import { WidgetWrapper } from "../widget-wrapper"
import * as S from "./styled"

const PEPIndexedDBValidator = z.object({
  data: z.tuple([
    z.array(z.tuple([z.number(), z.number()])),
    z.array(z.tuple([z.number(), z.number()])),
    z.array(z.tuple([z.number(), z.number()])),
  ]),
  savedAt: z.number(),
})

const PepGraph: GraphReactComponent = ({
  graphs,
  setGraphs,
  socket,
  broker,
  pair,
  id,
  rangeMin,
  rangeMax,
}) => {
  const singleChart = useRef<HighchartsReact.RefObject>(null)
  const brokerPair = getBrokerPair(broker, pair)
  const precision = useMemo(() => getPairFromId(pair).bpPrecision, [pair])
  const [loadedHistorical, setLoadedHistorical] = useState(false)
  const [graphsExtremes, setGraphsExtremes] = useState<{
    min: number
    max: number
  }>({
    min: rangeMin ? rangeMin : Date.now() - 1000 * 60 * 30,
    max: rangeMax ? rangeMax : Date.now(),
  })

  const wrapperRef = useRef<HTMLDivElement>(null)

  useResizeObserver(wrapperRef, () => {
    singleChart?.current?.chart?.yAxis?.[0]?.update(
      {
        visible: window.innerWidth > breakpointNum.mobile,
      },
      true,
    )
    return window.dispatchEvent(new Event("resize"))
  })

  const setExtremes =
    useCallback<Highcharts.AxisSetExtremesEventCallbackFunction>(e => {
      if (e.trigger !== "syncExtremes") {
        setGraphsExtremes({ min: e.min, max: e.max })
        e.preventDefault()
      }
    }, [])

  const { getCurrentUser } = useContext(AuthContext)
  useEffect(() => {
    if (loadedHistorical) return
    const asyncTrick = async () => {
      try {
        const url =
          (process.env.REACT_APP_ENDPOINT || "http://localhost:4000") +
          "/liquidity-dashboard/PEP"

        const user = await getCurrentUser()
        if (!user.isLogged) return

        const indexDbKey = `pep-${brokerPair}`

        const loadedSafeData = PEPIndexedDBValidator.safeParse(
          await indexDb.get(indexDbKey),
        )
        let cachedData: z.infer<typeof PEPIndexedDBValidator> = {
          data: [[], [], []],
          savedAt: 0,
        }
        if (loadedSafeData.success) {
          cachedData = loadedSafeData.data
        }

        if (cachedData.savedAt < getWeekStart().getTime()) {
          cachedData = { data: [[], [], []], savedAt: 0 }
          indexDb.remove(indexDbKey)
        }

        const { data: res } = await axios.get<{
          data: [[number, number][], [number, number][], [number, number][]]
          savedAt: number
        }>(url, {
          params: {
            pair,
            broker: broker.id,
            from: cachedData.savedAt,
          },
          headers: {
            Authorization: user.tokens.token,
          },
        })

        const historicalData = zip(cachedData.data, res.data).map(
          ([cachedSeries, resSeries]) => [...cachedSeries, ...resSeries],
        )
        indexDb.put(indexDbKey, {
          savedAt: res.savedAt,
          data: historicalData,
        })

        const allSeries = ["Price", "Exposure", "PNL"]

        zip(allSeries, historicalData).forEach(([serieName, points], index) => {
          const graphData = getSerieGraphData(singleChart || [], serieName)

          const newData = mergeNewData(points, graphData[0])

          singleChart.current?.chart.series[index].setData(
            newData,
            false,
            false,
            false,
          )
        })

        // if we are passing both rangeMin and rangeMax
        if (rangeMin && rangeMax) {
          const shift = rangeMax - rangeMin
          const dataMin = (singleChart.current?.chart.series[0] as any).xData[0]
          const dataMax = (
            singleChart.current?.chart.series[0] as any
          ).xData.at(-1)

          const graphMin = Math.max(rangeMin, dataMin)
          const proposedMax = Math.max(graphMin + shift, rangeMax)
          const graphMax = Math.min(proposedMax, dataMax)
          singleChart.current?.chart.xAxis[0].setExtremes(
            graphMin,
            graphMax,
            true,
            false,
          )
        } else singleChart.current?.chart.redraw(false)

        setLoadedHistorical(true)
      } catch (err) {
        console.error(err)
      }
    }
    asyncTrick()
  }, [
    broker,
    pair,
    getCurrentUser,
    brokerPair,
    loadedHistorical,
    rangeMax,
    rangeMin,
  ])

  const pepListener = useCallback(
    (tick: PepTick) => {
      if (tick.brokerPair !== brokerPair) return

      const priceSeries = singleChart?.current?.chart?.series[0]
      priceSeries?.addPoint(
        [tick.data.timestamp, tick.data.price],
        false,
        false,
      )

      const exposureSeries = singleChart?.current?.chart?.series[1]
      exposureSeries?.addPoint(
        [tick.data.timestamp, tick.data.sizeUSD],
        false,
        false,
      )

      const pnlSeries = singleChart?.current?.chart?.series[2]
      pnlSeries?.addPoint(
        [tick.data.timestamp, tick.data.equityUSD],
        false,
        false,
      )
    },
    [brokerPair],
  )

  useEffect(() => {
    if (!socket) return () => {}
    socket.on("pep", pepListener)

    return () => socket.off("pep", pepListener)
  }, [socket, pepListener])

  const graphInfo: GraphInfo = useMemo(
    () => ({
      broker,
      pair,
      type: "pep-beta",
      id,
      rangeMin: graphsExtremes.min,
      rangeMax: graphsExtremes.max,
      Graph: PepGraph,
    }),
    [broker, id, pair, graphsExtremes.min, graphsExtremes.max],
  )

  return (
    <WidgetWrapper>
      <WidgetHeader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
        widgetTitle="PEP Graph (Beta)"
      />
      <WidgetSubheader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
      />
      <S.PepWrapper
        className="immovable"
        onMouseDown={e => e.stopPropagation()}
        ref={wrapperRef}
      >
        <PEPSingleMemoizedGraph
          chartComponentRef={singleChart}
          setExtremes={setExtremes}
          precision={precision}
        />
      </S.PepWrapper>
    </WidgetWrapper>
  )
}

export default PepGraph

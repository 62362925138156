import styled from "styled-components"
import { breakpointNum, colors, palette } from "../../styles"
import * as T from "../typography"

export const GridHeader = styled(T.Notes)`
  color: ${colors.iMediumGrey};
  text-align: right;
  :first-child {
    text-align: left;
  }
  :nth-child(2) {
    text-align: center;
  }
  white-space: nowrap;
`

export const GridWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-row-gap: 5px;
  grid-column-gap: 10px;
  padding: 10px 2px;
  @media (max-width: ${breakpointNum.mobile}px) {
    grid-column-gap: 5px;
  }
`

export const CellCenter = styled(T.P3)`
  color: white;
  text-align: center;
  @media (max-width: ${breakpointNum.mobile}px) {
    font-size: 9px;
  }
`

export const CellLeft = styled(T.P3)`
  color: white;
  text-align: left;
  min-width: 72px;
  @media (max-width: ${breakpointNum.mobile}px) {
    font-size: 9px;
  }
`

export const CellRight = styled(T.P3)`
  color: white;
  text-align: right;
  @media (max-width: ${breakpointNum.mobile}px) {
    font-size: 9px;
  }
`

export const SpinnerWrapper = styled.div`
  flex-flow: column;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-spin .ant-spin-dot-holder {
    color: ${palette.primary};
  }
`

export const CellTotalCenter = styled(CellCenter)`
  font-weight: bold;
  margin-top: 15px;
`

export const CellTotalLeft = styled(CellLeft)`
  font-weight: bold;
  margin-top: 15px;
`

export const CellTotalRight = styled(CellRight)`
  font-weight: bold;
  margin-top: 15px;
`

import styled from "styled-components"
import { palette } from "../../styles"

export const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  .ant-spin .ant-spin-dot-holder {
    color: ${palette.primary};
  }
`

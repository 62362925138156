import styled from "styled-components"
import * as T from "../typography"
import { colors } from "../../styles"

export const GridWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 95px 0.5fr 2fr 1.9fr 1.5fr;
  margin-top: 8px;
  row-gap: 8px;
`

export const GridHeader = styled(T.Notes)`
  color: ${colors.iMediumGrey};
  padding-right: 1px;
  text-align: right;
  :first-child {
    text-align: left;
  }
  white-space: nowrap;
`

export const GridBrokerLabel = styled(T.P3)`
  font-family: Lato-Bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 3px 3px 3px 0;
`

export const GridLabel = styled(T.P3)`
  text-align: right;
  padding: 3px;
`

export const RowWrapper = styled.div`
  display: contents;
  :hover > * {
    background-color: ${colors.iDarkGrey};
    transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`

import { Select } from "antd"
import _ from "lodash"
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import RGL from "react-grid-layout"
import { GraphsList, SavedLayoutsType } from "../../types"
import { AuthContext } from "../auth-context"
import ManageDashboardModal from "../manage-dashboard-modal"
import SaveDashboardLayoutModal from "../save-dashboard-layout-modal"
import StyledButton from "../small-rounded-button"
import { PairsBrokersListContext } from "../used-pairs-brokers-context"
import {
  createSaveLocalNewLayout,
  createSaveRemoteNewLayout,
  filterOutGraphsNotPresentAnymore,
  getRemoteSavedLayouts,
  layoutsBrokerIdToBroker,
  swapLayout,
  updateLocalSavedLayout,
  updateRemoteExistingLayout,
} from "./helpers"
import * as S from "./styled"

const LiquidityDashboardHeader: React.FC<{
  graphs: GraphsList
  setGraphs: React.Dispatch<React.SetStateAction<GraphsList>>
  layouts: RGL.Layout[]
  setLayouts: React.Dispatch<React.SetStateAction<RGL.Layout[]>>
  currentLayoutName: string
  setCurrentLayoutName: React.Dispatch<React.SetStateAction<string>>
}> = ({
  setGraphs,
  graphs,
  layouts,
  setLayouts,
  currentLayoutName,
  setCurrentLayoutName,
}) => {
  const [savedLayouts, setSavedLayouts] = useState<SavedLayoutsType>([])
  const [allVWAP, setAllVWAP] = useState<boolean>(false)
  const [allComm, setAllComm] = useState<boolean>(false)
  const [isLayoutChanged, setIsLayoutChanged] = useState<boolean>(false)
  const [openModalManageDashboard, setOpenModalManageDashboard] =
    useState<boolean>(false)
  const [openModalSaveLayout, setOpenModalSaveLayout] = useState<boolean>(false)
  const { getCurrentUser } = useContext(AuthContext)
  const { brokersList } = useContext(PairsBrokersListContext)
  const backendGETCall = useRef<boolean>(false)
  const url = useMemo(
    () =>
      (process.env.REACT_APP_ENDPOINT || "http://localhost:4000") +
      "/liquidity-dashboard/layouts",
    [],
  )

  useLayoutEffect(() => {
    if (backendGETCall.current) return
    backendGETCall.current = true
    getCurrentUser().then(user => {
      if (!user.isLogged) return
      getRemoteSavedLayouts(user.tokens.token, url)
        .then(res => {
          const reshapedData = layoutsBrokerIdToBroker(res.data, brokersList)
          const onlyValidGraphs = filterOutGraphsNotPresentAnymore(reshapedData)
          setSavedLayouts(onlyValidGraphs)
        })
        .catch(err => {
          backendGETCall.current = false
          console.error(err)
        })
    })
  }, [getCurrentUser, url, brokersList])

  useEffect(() => {
    setAllVWAP(graphs.every(g => g.vwap))
    setAllComm(graphs.every(g => g.comm))
  }, [graphs])

  useLayoutEffect(() => {
    const presentLayouts = savedLayouts.find(
      l => l.name === currentLayoutName,
    )?.layouts
    if (
      _.isEqual(
        _.sortBy(presentLayouts?.map(el => _.omitBy(el, _.isUndefined))),
        _.sortBy(layouts?.map(el => _.omitBy(el, _.isUndefined))),
      )
    ) {
      setIsLayoutChanged(false)
      return
    } else setIsLayoutChanged(true)
  }, [layouts, currentLayoutName, savedLayouts])

  const createSelectOptions = useMemo(
    () =>
      savedLayouts.map(layout => (
        <Select.Option key={layout.name} value={layout.name}>
          {layout.name}
        </Select.Option>
      )),
    [savedLayouts],
  )

  const handleSaveClick = async (name: string) => {
    const user = await getCurrentUser()
    if (!user.isLogged) return
    const newLayout = {
      name,
      graphs,
      layouts,
    }
    if (savedLayouts.some(l => l.name === name)) {
      await updateRemoteExistingLayout(user.tokens.token, url, newLayout)
        .then(_ => {
          updateLocalSavedLayout(name, graphs, layouts, setSavedLayouts)
          setIsLayoutChanged(false)
          setCurrentLayoutName(name)
        })
        .catch(console.error)
      return
    }

    await createSaveRemoteNewLayout(user.tokens.token, url, newLayout)
      .then(_ => {
        createSaveLocalNewLayout(name, graphs, layouts, setSavedLayouts)
        setIsLayoutChanged(false)
        setCurrentLayoutName(name)
      })
      .catch(console.error)

    return
  }

  return (
    <S.HeaderWrapper>
      <S.HeaderLeftMenu>
        {(savedLayouts.length === 1 && !currentLayoutName) ||
        savedLayouts.length > 1 ? (
          <S.SelectWrapper>
            <S.WrapperHexagon isDropdown={true}>
              <S.HexagonStyled width={12} />
            </S.WrapperHexagon>
            <S.StyledSelect
              variant="borderless"
              onClick={e => e.preventDefault()}
              suffixIcon={<S.ArrowDropDownStyled />}
              value={currentLayoutName || "Unnamed dashboard"}
              onSelect={(e: unknown) => {
                if (typeof e !== "string") return

                swapLayout(
                  setGraphs,
                  setLayouts,
                  e,
                  setCurrentLayoutName,
                  savedLayouts,
                )
              }}
              getPopupContainer={trigger => {
                return trigger
              }}
              $unnamed={!!currentLayoutName}
            >
              {createSelectOptions}
            </S.StyledSelect>
            {createSelectOptions}
          </S.SelectWrapper>
        ) : (
          <S.HexagonLabelWrapper>
            <S.WrapperHexagon isDropdown={false}>
              <S.HexagonStyled width={12} />
            </S.WrapperHexagon>
            {currentLayoutName ? (
              <S.SavedDashboardLabel
                onClick={() => {
                  swapLayout(
                    setGraphs,
                    setLayouts,
                    currentLayoutName,
                    setCurrentLayoutName,
                    savedLayouts,
                  )
                  setIsLayoutChanged(false)
                }}
              >
                {currentLayoutName}
              </S.SavedDashboardLabel>
            ) : (
              <S.NotSavedDashboardLabel>
                Unnamed Dashboard
              </S.NotSavedDashboardLabel>
            )}
          </S.HexagonLabelWrapper>
        )}
        {isLayoutChanged ? (
          <>
            <S.ButtonWrapper
              style={{ paddingLeft: 20 }}
              onClick={() => {
                if (!currentLayoutName) setOpenModalSaveLayout(true)
                else handleSaveClick(currentLayoutName)
              }}
            >
              <S.SaveStyled title={""} />
              <S.ButtonLabel>Save</S.ButtonLabel>
            </S.ButtonWrapper>
            <S.ButtonWrapper
              style={{ paddingLeft: 8 }}
              onClick={() => setOpenModalSaveLayout(true)}
            >
              <S.SaveStyled title={""} />
              <S.ButtonLabel>Save as...</S.ButtonLabel>
            </S.ButtonWrapper>
          </>
        ) : null}
      </S.HeaderLeftMenu>
      <S.HeaderRightMenu>
        {savedLayouts.length > 0 ? (
          <S.ButtonWrapper onClick={() => setOpenModalManageDashboard(true)}>
            <S.BlocksStyled />
            <S.ButtonLabel separatorRight>Manage Dashboard</S.ButtonLabel>
          </S.ButtonWrapper>
        ) : null}
        <S.ButtonWrapper onClick={() => setGraphs([])}>
          <S.SweepStyled />
          <S.ButtonLabel separatorRight>Remove all widgets</S.ButtonLabel>
        </S.ButtonWrapper>
        {false && (
          <S.RoundSmallButtonsWrapper>
            <StyledButton
              shape="round"
              size="small"
              selected={allVWAP}
              onClick={() => {
                setAllVWAP(!allVWAP)
                setGraphs(graphs.map(g => ({ ...g, vwap: !allVWAP })))
              }}
            >
              VWAP ALL
            </StyledButton>
            <StyledButton
              shape="round"
              size="small"
              selected={allComm}
              onClick={() => {
                setAllComm(!allComm)
                setGraphs(graphs.map(g => ({ ...g, comm: !allComm })))
              }}
            >
              COMM ALL
            </StyledButton>
          </S.RoundSmallButtonsWrapper>
        )}
      </S.HeaderRightMenu>
      {openModalManageDashboard && (
        <ManageDashboardModal
          savedLayouts={savedLayouts}
          setOpenModalManageDashboard={setOpenModalManageDashboard}
          updateSavedLayouts={setSavedLayouts}
          currentLayoutName={currentLayoutName}
          setCurrentLayoutName={setCurrentLayoutName}
        />
      )}
      {openModalSaveLayout && (
        <SaveDashboardLayoutModal
          updateSavedLayouts={setSavedLayouts}
          setOpenModalSaveLayout={setOpenModalSaveLayout}
          graphs={graphs}
          layouts={layouts}
          setCurrentLayoutName={setCurrentLayoutName}
          setIsLayoutChanged={setIsLayoutChanged}
        />
      )}
    </S.HeaderWrapper>
  )
}

export default LiquidityDashboardHeader

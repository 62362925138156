import React, { useContext, useEffect, useMemo } from "react"
import { BlocksStyled, GraphIcon as ModalIcon } from "../../styles"
import { ReactSetter, SavedLayoutsType } from "../../types"
import { AuthContext } from "../auth-context"
import { deleteLocalLayoutByName, deleteRemoteLayoutByName } from "./helpers"
import * as S from "./styled"

const ManageDashboardModal: React.FC<{
  savedLayouts: SavedLayoutsType
  updateSavedLayouts: ReactSetter<SavedLayoutsType>
  setOpenModalManageDashboard: React.Dispatch<React.SetStateAction<boolean>>
  currentLayoutName: string
  setCurrentLayoutName: React.Dispatch<React.SetStateAction<string>>
}> = ({
  savedLayouts,
  setOpenModalManageDashboard,
  updateSavedLayouts,
  currentLayoutName,
  setCurrentLayoutName,
}) => {
  const { getCurrentUser } = useContext(AuthContext)
  const url = useMemo(
    () =>
      (process.env.REACT_APP_ENDPOINT || "http://localhost:4000") +
      "/liquidity-dashboard/layouts",
    [],
  )

  useEffect(() => {
    if (savedLayouts.length === 0) setOpenModalManageDashboard(false)
  }, [savedLayouts, setOpenModalManageDashboard])

  return (
    <S.ModalWrapper>
      <S.ModalHeader>
        <S.ModalHeaderLeft>
          <ModalIcon icon={BlocksStyled} />
          <S.ModalHeaderTitle>Manage your dashboards</S.ModalHeaderTitle>
        </S.ModalHeaderLeft>
        <S.CloseStyled onClick={() => setOpenModalManageDashboard(false)} />
      </S.ModalHeader>
      {savedLayouts.map((layout, index) => (
        <React.Fragment key={layout.name}>
          <S.HorizontalSeparator isFirst={index === 0} />
          <S.ModalRow>
            <S.StyledBin
              title={""}
              onClick={async () => {
                const user = await getCurrentUser()
                if (!user.isLogged) return
                deleteRemoteLayoutByName(user.tokens.token, url, layout.name)
                  .then(_ => {
                    if (layout.name === currentLayoutName)
                      setCurrentLayoutName("")
                    deleteLocalLayoutByName(layout.name, updateSavedLayouts)
                  })
                  .catch(console.error)
              }}
            />
            <S.ModalLabel>{layout.name}</S.ModalLabel>
          </S.ModalRow>
        </React.Fragment>
      ))}
    </S.ModalWrapper>
  )
}

export default ManageDashboardModal

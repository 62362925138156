import { Alert, ColorPicker, Form, Spin } from "antd"
import axios from "axios"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { getColor, getPairStrip, typedEntries } from "../../helpers"
import { ColorsDataType } from "../../types"
import { AuthContext } from "../auth-context"
import { sortPairs } from "./helpers"
import * as S from "./styled"

const url =
  (process.env.REACT_APP_ENDPOINT || "http://localhost:4000") +
  "/settings/colors"

const colorTypes = ["PoP/Brokers", "Brokers", "Pairs", "Currencies"] as const

const SettingsColorsCard = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [form] = Form.useForm()
  const formRef = useRef(null)
  const { getCurrentUser } = useContext(AuthContext)
  const [allData, setAllData] = useState<ColorsDataType>({
    pairs: [],
    popBrokers: [],
    brokers: [],
    currencies: [],
    colorsData: {},
  })
  const [itemsList, setItemList] = useState<string[]>([])
  const [colorTypeIndex, setColorTypeIndex] = useState(0)
  const [responseSuccess, setResponseSuccess] = useState<boolean>()
  const [responseError, setResponseError] = useState<boolean>()

  const getColorsData = useCallback(
    () =>
      getCurrentUser()
        .then(user => {
          if (!user.isLogged) return
          axios
            .get<ColorsDataType>(url, {
              headers: {
                Authorization: user.tokens.token,
              },
            })
            .then(({ data }) => {
              setAllData(data)
              if (colorTypeIndex === 0) setItemList(data?.popBrokers ?? [])
              else if (colorTypeIndex === 1) setItemList(data?.brokers ?? [])
              else if (colorTypeIndex === 2)
                setItemList(data?.pairs ? sortPairs(data?.pairs) : [])
              else if (colorTypeIndex === 3) setItemList(data?.currencies ?? [])
              setIsLoading(false)
            })
        })
        .catch(console.error),
    [getCurrentUser, colorTypeIndex],
  )

  useEffect(() => {
    setIsLoading(true)
    getColorsData()
  }, [getColorsData])

  const onFinish = async (values: any) => {
    setResponseSuccess(false)
    setResponseError(false)
    const dataToSend = {
      colorsData: typedEntries(values).reduce((acc, [key, color]) => {
        return {
          ...acc,
          [key]: typeof color === "string" ? color : color.toHexString(),
        }
      }, allData?.colorsData),
    }
    const user = await getCurrentUser()
    if (!user.isLogged) return

    await axios
      .post(url, dataToSend, {
        headers: {
          Authorization: user.tokens.token,
        },
      })
      .then(res => {
        const newAllData = {
          ...allData,
          colorsData: res.data,
        }
        setResponseSuccess(true)
        setAllData(newAllData)
      })
      .catch(err => {
        console.error(err)
        setResponseError(true)
      })
  }

  return (
    <S.WrapperCard isLoading={isLoading}>
      {isLoading ? (
        <S.SpinnerWrapper>
          <Spin />
        </S.SpinnerWrapper>
      ) : (
        <div className="immovable">
          <S.TitleWrapper>
            <S.TitleCard>Colors Configuration</S.TitleCard>
          </S.TitleWrapper>
          <S.TypeSelectContainerDiv>
            <S.TypeLabel>Color set </S.TypeLabel>
            <S.SelectStyled
              value={colorTypeIndex}
              onChange={value => {
                if (typeof value !== "number") return
                setColorTypeIndex(value)
                if (value === 0) setItemList(allData?.popBrokers ?? [])
                else if (value === 1) setItemList(allData?.brokers ?? [])
                else if (value === 2)
                  setItemList(allData?.pairs ? sortPairs(allData?.pairs) : [])
                else if (value === 3) setItemList(allData?.currencies ?? [])
                setResponseError(false)
                setResponseSuccess(false)
              }}
              onMouseDown={e => e.stopPropagation()}
              disabled={false}
              options={colorTypes.map((colType, index) => ({
                value: index,
                label: colType.toUpperCase(),
              }))}
            />
          </S.TypeSelectContainerDiv>
          <S.FormStyled
            name="colorForm"
            layout={"horizontal"}
            onFinish={onFinish}
            form={form}
            ref={formRef}
            requiredMark={false}
          >
            <S.Grid>
              {itemsList &&
                itemsList.map((value, index) => {
                  const currentColor = getColor(allData.colorsData, value)
                  const label =
                    colorTypeIndex === 2
                      ? getPairStrip(value)
                      : value.toLowerCase() === "aggregated"
                        ? "CONSOLIDATED"
                        : value.toUpperCase()
                  return (
                    <S.GridRow key={`${index}`}>
                      <S.GridCell key={`${index}-row`}>{label}</S.GridCell>
                      <S.GridCellRight key={`${index}-row-col`}>
                        <S.FormItemStyled
                          label={null}
                          name={value.toUpperCase()}
                          colon={false}
                          rules={[
                            {
                              required: true,
                              message: "Please input a color!",
                            },
                          ]}
                          initialValue={currentColor}
                        >
                          <ColorPicker value={currentColor} showText />
                        </S.FormItemStyled>
                      </S.GridCellRight>
                    </S.GridRow>
                  )
                })}
            </S.Grid>
            <S.FormItemStyled {...{ wrapperCol: { span: 24, offset: 0 } }}>
              <S.ButtonStyled type="primary" htmlType="submit">
                Submit
              </S.ButtonStyled>
            </S.FormItemStyled>
          </S.FormStyled>
          {responseSuccess ? (
            <Alert message="Data successfully updated" type="success" />
          ) : null}
          {responseError ? (
            <Alert message="Unfortunately an error occurred" type="error" />
          ) : null}
        </div>
      )}
    </S.WrapperCard>
  )
}

export default SettingsColorsCard

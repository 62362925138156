import { Form, InputNumber, Spin } from "antd"
import axios from "axios"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { ExposureStartingBalanceDataType } from "../../types"
import { AuthContext } from "../auth-context"

import { Alert } from "antd"
import { typedEntries } from "../../helpers"
import { parseReceivedData } from "./helpers"

import * as S from "./styled"

const url =
  (process.env.REACT_APP_ENDPOINT || "http://localhost:4000") +
  "/settings/starting-balances"

const SettingsStartingBalanceCard = () => {
  const [form] = Form.useForm()
  const formRef = useRef(null)
  const { getCurrentUser } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [allData, setAllData] = useState<ExposureStartingBalanceDataType>()
  const [responseError, setResponseError] = useState<boolean>()
  const [responseSuccess, setResponseSuccess] = useState<boolean>()
  const [defaultValues, setDefaultValues] = useState<{}>({})
  const [totalDeposit, setTotalDeposit] = useState<number>(0)

  const getStartingBalanceData = useCallback(
    () =>
      getCurrentUser()
        .then(user => {
          if (!user.isLogged) return
          axios
            .get<ExposureStartingBalanceDataType>(url, {
              headers: {
                Authorization: user.tokens.token,
              },
            })
            .then(({ data }) => {
              setAllData(data)
              setIsLoading(false)

              const formReceivedData = parseReceivedData(data)

              setDefaultValues(formReceivedData.formKeys)
              setTotalDeposit(formReceivedData.depositAmount)
            })
        })
        .catch(console.error),
    [getCurrentUser],
  )

  useEffect(() => {
    setIsLoading(true)
    getStartingBalanceData()
  }, [getStartingBalanceData])

  const onFinish = async (values: any) => {
    const dataToSend = {
      popConf: typedEntries(values).reduce(
        (acc, valueArray) => {
          return { ...acc, [valueArray[0]]: valueArray[1] }
        },
        {} as Record<string, number>,
      ),
    }
    const user = await getCurrentUser()
    if (!user.isLogged) return
    await axios
      .post(url, dataToSend, {
        headers: {
          Authorization: user.tokens.token,
        },
      })
      .then(res => {
        if (allData) {
          const newAllData = {
            ...allData,
            popConf: res.data,
          }
          const formReceivedData = parseReceivedData(newAllData)

          setAllData(newAllData)
          setResponseSuccess(true)
          setDefaultValues(formReceivedData.formKeys)
          setTotalDeposit(formReceivedData.depositAmount)
        } else throw new Error("Something went wrong")
      })
      .catch(err => {
        console.error(err)
        setResponseError(true)
      })
  }

  useEffect(() => {
    setResponseSuccess(false)
    setResponseError(false)
  }, [])

  return (
    <S.Wrapper isLoading={isLoading}>
      {isLoading ? (
        <S.SpinnerWrapper>
          <Spin />
        </S.SpinnerWrapper>
      ) : (
        <div className="immovable">
          <S.TitleWrapper>
            <S.Title>Starting Balance Configuration</S.Title>
          </S.TitleWrapper>
          <S.FormScrollable>
            <S.FormStyled
              name="balanceForm"
              layout={"horizontal"}
              onFinish={onFinish}
              form={form}
              ref={formRef}
              requiredMark={false}
              initialValues={defaultValues}
            >
              <S.Grid>
                <S.GridHeader key="firstheader">
                  <S.HeaderLabel key={`PopBroker-header`}>
                    POP/Broker
                  </S.HeaderLabel>
                  <S.HeaderLabel key={`StartingBalance-header`}>
                    Starting Balance
                  </S.HeaderLabel>
                </S.GridHeader>

                {allData &&
                  allData.popBrokers.map((brokername, index) => {
                    return (
                      <S.GridRow key={index}>
                        <S.GridCell key={`${index}-row`}>
                          {brokername}
                        </S.GridCell>
                        <S.GridCell key={`${index}-startingbal`}>
                          <S.FormItemStyled
                            label={null}
                            name={brokername}
                            colon={false}
                            rules={[
                              {
                                required: true,
                                message: "Please input a starting balance!",
                              },
                            ]}
                          >
                            <InputNumber
                              controls={false}
                              formatter={value => {
                                if (value === undefined) return "0"
                                return new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                  maximumFractionDigits: 0,
                                  minimumFractionDigits: 0,
                                }).format(Number(value))
                              }}
                            />
                          </S.FormItemStyled>
                        </S.GridCell>
                      </S.GridRow>
                    )
                  })}
                <S.GridRow>
                  <S.GridCell key={`totaldeposit`}>Total Deposit</S.GridCell>
                  <S.GridCellRight key={`totaldepositvalue`}>
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                    }).format(Number(totalDeposit))}
                  </S.GridCellRight>
                </S.GridRow>
              </S.Grid>
              <S.FormItemStyled {...{ wrapperCol: { span: 24, offset: 0 } }}>
                <S.ButtonStyled type="primary" htmlType="submit">
                  Submit
                </S.ButtonStyled>
              </S.FormItemStyled>
            </S.FormStyled>
          </S.FormScrollable>
          {responseSuccess ? (
            <Alert message="Data successfully updated" type="success" />
          ) : null}
          {responseError ? (
            <Alert message="Unfortunately an error occurred" type="error" />
          ) : null}
        </div>
      )}
    </S.Wrapper>
  )
}

export default SettingsStartingBalanceCard

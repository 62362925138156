import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons"
import { Input } from "antd"
import { useContext, useMemo, useState } from "react"
import { AuthContext } from "../../components/auth-context"
import { submitCredentials } from "./helpers"
import * as S from "./styled"

const UserSignUp = () => {
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [firstName, setFirstName] = useState<string>("")
  const [lastName, setLastName] = useState<string>("")
  const [confirmPassword, setConfirmPassword] = useState<string>("")
  const [error, setError] = useState<string>("")
  const [success, setSuccess] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { getCurrentUser } = useContext(AuthContext)

  const submit = () =>
    getCurrentUser().then(user => {
      user.isLogged &&
        submitCredentials(
          email,
          password,
          confirmPassword,
          firstName,
          lastName,
          setIsLoading,
          setError,
          setSuccess,
          user.tokens.token,
        )
    })

  const HTMLInputs = useMemo(
    () => [
      { placeholder: "First name", setter: setFirstName, isPassword: false },
      { placeholder: "Last name", setter: setLastName, isPassword: false },
      { placeholder: "Email", setter: setEmail, isPassword: false },
      { placeholder: "Password", setter: setPassword, isPassword: true },
      {
        placeholder: "Confirm password",
        setter: setConfirmPassword,
        isPassword: true,
      },
    ],
    [],
  )

  return (
    <S.Wrapper>
      <S.FormWrapper>
        <S.TitleForm>Sign up</S.TitleForm>
        {success ? (
          <S.AlertStyled
            message=""
            description={success}
            type="success"
            showIcon
          />
        ) : null}
        {error ? (
          <S.AlertStyled message="" description={error} type="error" showIcon />
        ) : null}
        {HTMLInputs.map(({ placeholder, setter, isPassword }) => {
          const InputComponent = isPassword ? Input.Password : Input
          return (
            <InputComponent
              placeholder={placeholder}
              onChange={e => setter(e.target.value)}
              onPressEnter={submit}
              {...(isPassword
                ? {
                    iconRender: visible =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />,
                  }
                : {})}
              key={placeholder}
            />
          )
        })}
        <S.ButtonStyled
          type="primary"
          onClick={submit}
          disabled={isLoading}
          loading={isLoading}
        >
          Submit
        </S.ButtonStyled>
      </S.FormWrapper>
    </S.Wrapper>
  )
}

export default UserSignUp

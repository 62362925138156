import { CurrencyUppercase } from "../../types"

const getPrecisionRate = (currency: CurrencyUppercase) => {
    switch(currency) {
      case "JPY": return 3
      case "BTC": return 0
      case "XAU": return 1
      default: return 5
    }
  }

const formatRateFX = (rate: number, currency: CurrencyUppercase) =>
  rate.toLocaleString("en", {
    minimumFractionDigits: getPrecisionRate(currency),
    maximumFractionDigits: getPrecisionRate(currency),
  })

const formatRateCrypto = (rate: number, currency: CurrencyUppercase) =>
  rate.toLocaleString("en", {
    minimumFractionDigits: getPrecisionRate(currency),
    maximumFractionDigits: getPrecisionRate(currency),
  })

export const formatRate =
  process.env.REACT_APP_INSTANCE_TYPE === "crypto"
    ? formatRateCrypto
    : formatRateFX

const formatExposureFX = (amount: number, currency: CurrencyUppercase) =>
  amount.toLocaleString("en", {
    style: "currency",
    currency: currency,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    currencyDisplay: "narrowSymbol",
  })

export const formatExposureCrypto = (
  amount: number,
  currency: CurrencyUppercase,
) =>
  amount.toLocaleString("en", {
    style: "currency",
    currency: currency,
    maximumFractionDigits: currency === "BTC" ? 2 : 5,
    minimumFractionDigits: currency === "BTC" ? 2 : 5,
    currencyDisplay: "narrowSymbol",
  })

export const formatExposure =
  process.env.REACT_APP_INSTANCE_TYPE === "crypto"
    ? formatExposureCrypto
    : formatExposureFX

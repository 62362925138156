import styled from "styled-components"
import { Check } from "styled-icons/bootstrap"
import { Handshake } from "styled-icons/fa-regular"
import { Cross } from "styled-icons/icomoon"
import { colors, palette } from "../../styles"
import * as T from "../typography"
import { Button } from "antd"

export const Wrapper = styled.div<{ isLoading: boolean }>`
  background-color: ${colors.iUltraDarkGrey};
  border-radius: 4px;
  min-width: 300px;
  min-height: 120px;
  height: 100%;
  padding: 8px 16px;
  overflow-x: auto;
  ${({ isLoading }) =>
    isLoading
      ? `display: flex;
      align-items: center;
      justify-content: center;`
      : ``}
  @media (max-width: 599px) {
    min-width: 200px;
  }
`

export const SpinnerWrapper = styled.div`
  .ant-spin .ant-spin-dot-holder {
    color: ${palette.primary};
  }
`

export const Header = styled.div`
  text-align: center;
  width: 100%;
  padding-bottom: 6px;
  margin-bottom: 9px;
  color: ${colors.iWhite};
  border-bottom: 1px solid ${colors.iMediumGrey};
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 2.5em;
`

export const Title = styled(T.H3)`
  flex-grow: 1;
`

export const SocketCheckButtonWrapper = styled.div`
  position: relative;
  min-width: 64px;
`

export const StyledButton = styled(Button)`
  position: absolute;
  left: 8px;
  top: -16px;
  background-color: ${palette.primary};
  border-color: ${palette.primary};
  :focus {
    background-color: ${palette.focus};
    border-color: ${palette.focus};
  }
  :hover {
    background-color: ${palette.hover};
    border-color: ${palette.hover};
  }
`

export const HeaderLabel = styled(T.H4)`
  color: ${colors.iWhite};
  text-align: center;
  margin: 0 0.2em 0.5em;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const Table = styled.div`
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 0.1em;
  align-items: center;
  height: 100%;
  max-height: 470px;
`

export const Row = styled.div`
  display: contents;
`

export const RowCell = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.5em;
`

export const CellVal = styled.div``

export const StyledCheck = styled(Check)`
  color: ${colors.iGreenText};
`

export const StyledCross = styled(Cross)`
  color: ${colors.iRedText};
`

export const HandshakeStyled = styled(Handshake)<{ $color: "red" | "green" }>`
  ${({ $color }) =>
    $color === "red" ? `color: ${colors.iRed}` : `color: ${colors.iGreenText}`};
`

import styled from "styled-components"
import * as T from "../typography"

export const Row = styled.div`
  padding: 10px 0;
  display: grid;
  grid-template-columns: 35% 35% 1fr;
  column-gap: 16px;
  row-gap: 10px;
`

export const Label = styled(T.P3)`
  text-align: right;
`

export const Value = styled(T.H5)`
  text-overflow: ellipsis;
  overflow: hidden;
`
export const Percentage = styled(T.H5)``

import useResizeObserver from "@react-hook/resize-observer"
import axios from "axios"
import { Series } from "highcharts"
import HighchartsReact from "highcharts-react-official"
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useNavigate } from "react-router-dom"
import { z } from "zod"
import {
  getBrokerPair,
  getColor,
  getPairStrip,
  getWeekStart,
  mergeNewData,
  sortById,
  typedKeys,
  zip,
} from "../../helpers"
import indexDb from "../../helpers/indexDB"
import { breakpointNum } from "../../styles"
import {
  ExposurePairData,
  ExposurePairTick,
  GraphInfo,
  GraphReactComponent,
} from "../../types"
import { AuthContext } from "../auth-context"
import ExposurePairMemoizedGraph from "../exposure-pair-memoized-graph"
import { PairsBrokersListContext } from "../used-pairs-brokers-context"
import WidgetHeader from "../widget-header"
import { WidgetSubheader } from "../widget-subheader"
import { WidgetWrapper } from "../widget-wrapper"
import { formatExposure } from "./helpers"
import * as S from "./styled"

const exposurePairIndexedDBValidator = z.object({
  data: z
    .object({
      pair: z.string(),
      data: z.array(z.tuple([z.number(), z.number()])),
    })
    .array(),
  savedAt: z.number(),
})

const ExposurePair: GraphReactComponent = ({
  socket,
  broker,
  pair,
  graphs,
  setGraphs,
  id,
  vwap,
  comm,
}) => {
  const [exposurePairData, setExposurePairData] = useState<ExposurePairData>({
    timestamp: 0,
    exposures: [],
  })
  const { getCurrentUser } = useContext(AuthContext)
  const router = useNavigate()
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const rightContentWrapper = useRef<HTMLDivElement>(null)
  const [displayOptions, setDisplayOptions] = useState<
    {
      sortLabel: string
      value: string
      label: string
    }[]
  >([{ sortLabel: "00_ALL", value: "pairs", label: "ALL PAIRS" }])
  const [display, setDisplay] =
    useState<(typeof displayOptions)[number]["value"]>("pairs")
  const [loadedHistorical, setLoadedHistorical] = useState(false)
  const brokerPair = useMemo(() => getBrokerPair("aggregated", "all"), [])
  const { colorsMapper } = useContext(PairsBrokersListContext)

  const exposurePairListener = useCallback(
    (tick: ExposurePairTick) => {
      if (tick.brokerPair !== brokerPair) return

      const series: Series[] | undefined =
        chartComponentRef.current?.chart?.series
      const { timestamp, exposures } = tick.data

      setDisplayOptions(oldOptions =>
        [
          ...oldOptions,
          ...exposures.flatMap(({ pair }) => {
            const pairStrip = getPairStrip(pair)
            const pairInCombo = oldOptions.find(x => x.value === pairStrip)
            if (!pairInCombo) {
              return [
                {
                  sortLabel: pairStrip === "SUM" ? "00_SUM" : pair,
                  value: pairStrip,
                  label: pairStrip.toUpperCase(),
                },
              ]
            } else return []
          }),
        ].sort((a, b) => sortById(a.sortLabel, b.sortLabel)),
      )

      const nbSeriesPresent = exposures.reduce((acc, { pair, exposure }) => {
        const pairStrip = getPairStrip(pair)
        const serie = series?.find(x => x.name === pairStrip)

        if (!serie) {
          chartComponentRef.current?.chart?.addSeries(
            {
              data: [[timestamp, exposure]],
              type: "line",
              name: pairStrip,
              color: getColor(colorsMapper, pair.replace("aggregated-", "")),
              showInNavigator: true,
              boostThreshold: 1,
            },
            false,
            false,
          )
          return acc
        } else {
          serie.addPoint([timestamp, exposure], false, false)
          return acc + 1
        }
      }, 0)

      if (exposures.length === nbSeriesPresent)
        chartComponentRef.current?.chart.redraw()

      setExposurePairData(tick.data)
    },
    [brokerPair, colorsMapper],
  )

  useEffect(() => {
    if (!socket) return () => { }
    socket.on("exposurePairs", exposurePairListener)

    return () => socket.off("exposurePairs", exposurePairListener)
  }, [socket, graphs, exposurePairListener])

  useEffect(() => {
    if (!chartComponentRef) return
    chartComponentRef.current?.chart.series.forEach(serie => {
      if (serie.name.includes("Navigator")) return
      serie.setVisible(
        serie.name === display || (display === "pairs" && serie.name !== "SUM"),
      )
    })
    chartComponentRef.current?.chart.redraw(false)
  }, [display])

  useEffect(() => {
    if (loadedHistorical) return
    if (typedKeys(colorsMapper).length === 0) {
      console.warn("MISSING COLORS. Please Save a palette in the /settings")
      return
    }

    const asyncTrick = async () => {
      const user = await getCurrentUser()
      if (!user.isLogged) {
        router("/login")
        return
      }

      const indexDbKey = `exposurePair-${brokerPair}`

      const loadedSafeData = exposurePairIndexedDBValidator.safeParse(
        await indexDb.get(indexDbKey),
      )

      let cachedData: z.infer<typeof exposurePairIndexedDBValidator> = {
        data: [],
        savedAt: 0,
      }

      if (loadedSafeData.success) {
        cachedData = loadedSafeData.data
      }

      if (cachedData.savedAt < getWeekStart().getTime()) {
        cachedData = { data: [], savedAt: 0 }
        indexDb.remove(indexDbKey)
      }

      try {
        const { data: res } = await axios.get<
          z.infer<typeof exposurePairIndexedDBValidator>
        >(
          `${process.env.REACT_APP_ENDPOINT || ""
          }/liquidity-dashboard/pairs-exposure`,
          {
            headers: {
              Authorization: user.tokens.token,
            },
            params: {
              from: cachedData.savedAt,
            },
          },
        )

        const pairsList = [
          ...new Set([
            ...cachedData.data.map(({ pair }) => pair),
            ...res.data.map(({ pair }) => pair),
          ]),
        ]
        const historicalData = pairsList.map(pair => {
          const oldCachedData =
            cachedData.data.find(x => x.pair === pair)?.data ?? []
          const newResData = res.data.find(x => x.pair === pair)?.data ?? []
          return {
            pair,
            data: [...oldCachedData, ...newResData],
          }
        })

        const lastPoint = {
          timestamp: Math.max(
            ...historicalData.map(x => x.data.at(-1)?.[0] || 0),
          ),
          exposures: historicalData.map(x => ({
            pair: x.pair,
            exposure: x.data.at(-1)?.[1] ?? 0,
          })),
        }

        if (lastPoint) setExposurePairData(lastPoint)

        indexDb.put(indexDbKey, {
          savedAt: res.savedAt,
          data: historicalData,
        })

        const series: Series[] | undefined =
          chartComponentRef.current?.chart?.series

        setDisplayOptions(oldOptions =>
          [
            ...oldOptions,
            ...historicalData.flatMap(({ pair }) => {
              const pairStrip = getPairStrip(pair)
              const pairInCombo = oldOptions.find(x => x.value === pairStrip)
              if (!pairInCombo) {
                return [
                  {
                    sortLabel: pairStrip === "SUM" ? "00_SUM" : pair,
                    value: pairStrip,
                    label: pairStrip.toUpperCase(),
                  },
                ]
              } else return []
            }),
          ].sort((a, b) => {
            const pair1 = a.sortLabel
            const pair2 = b.sortLabel
            if (pair1 < pair2) return -1
            if (pair1 > pair2) return 1
            return 0
          }),
        )

        historicalData.forEach(({ pair, data }) => {
          const pairStrip = getPairStrip(pair)

          const serie = series?.find(x => x.name === pairStrip)

          if (!serie) {
            chartComponentRef.current?.chart?.addSeries(
              {
                data: data,
                type: "line",
                name: pairStrip,
                color: getColor(colorsMapper, pair.replace("aggregated-", "")),
                showInNavigator: true,
                boostThreshold: 1,
              },
              false,
              false,
            )
          } else {
            serie.setData(
              mergeNewData(
                data,
                zip(
                  (serie as any).xData as number[],
                  (serie as any).yData as number[],
                ),
              ),
              false,
              false,
            )
            serie.update({
              ...serie.options,
              color: getColor(colorsMapper, pair.replace("aggregated-", "")),
            })
          }
        })
        series?.forEach(serie => {
          if (serie.name.includes("Navigator")) return
          serie.setVisible(
            serie.name === display ||
            (display === "pairs" && serie.name !== "SUM"),
          )
        })
        chartComponentRef.current?.chart.redraw(false)
      } catch (err) {
        console.error(err)
      }
    }
    asyncTrick()
    setLoadedHistorical(true)
  }, [
    broker,
    getCurrentUser,
    router,
    brokerPair,
    display,
    loadedHistorical,
    colorsMapper,
  ])

  const graphInfo: GraphInfo = useMemo(
    () => ({
      broker,
      pair,
      type: "exposurePairs",
      id,
      Graph: ExposurePair,
      vwap,
      comm,
    }),
    [broker, comm, id, pair, vwap],
  )

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"))
    }, 10)
  }, [])

  useResizeObserver(rightContentWrapper, () => {
    chartComponentRef?.current?.chart?.yAxis?.[0]?.update(
      {
        visible: window.innerWidth > breakpointNum.mobile,
      },
      true,
    )
    return window.dispatchEvent(new Event("resize"))
  })

  return (
    <WidgetWrapper>
      <WidgetHeader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
        widgetTitle={
          process.env.REACT_APP_INSTANCE_TYPE === "crypto"
            ? "Pairs Exposure (BTC)"
            : "Pairs Exposure (USD)"
        }
      />
      <WidgetSubheader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
        options={[
          {
            type: "select",
            optionsValues: displayOptions,
            onChange: value => {
              setDisplay(value)
            },
            value:
              displayOptions.find(({ value }) => display === value)?.label ??
              "",
          },
        ]}
      />
      <S.ContentWrapper>
        <S.LeftContentWrapper>
          <S.Grid>
            {exposurePairData.exposures
              .filter(({ pair }) => !pair.includes("-all"))
              .sort((p1, p2) => {
                const pair1 = p1.pair
                const pair2 = p2.pair
                if (pair1 < pair2) return -1
                if (pair1 > pair2) return 1
                return 0
              })
              .map(({ pair, exposure }) => (
                <S.Row key={pair}>
                  <S.PairDotWrapper>
                    <S.Dot
                      color={getColor(
                        colorsMapper,
                        pair.replace("aggregated-", ""),
                      )}
                    />
                    <S.Value>{getPairStrip(pair)}</S.Value>
                  </S.PairDotWrapper>
                  <S.Value>{formatExposure(exposure)}</S.Value>
                </S.Row>
              ))}
          </S.Grid>
          <S.ConsolidatedExposureRow>
            <S.Value>SUM</S.Value>
            <S.Value>
              {formatExposure(
                exposurePairData.exposures.find(
                  ({ pair }) => pair === "aggregated-all",
                )?.exposure ?? 0,
              )}
            </S.Value>
          </S.ConsolidatedExposureRow>
        </S.LeftContentWrapper>
        <S.RightContentWrapper
          className="immovable"
          ref={rightContentWrapper}
          onMouseDown={e => e.stopPropagation()}
        >
          <ExposurePairMemoizedGraph chartComponentRef={chartComponentRef} />
        </S.RightContentWrapper>
      </S.ContentWrapper>
    </WidgetWrapper>
  )
}

export default ExposurePair

import { PlusOutlined } from "@ant-design/icons"
import { Alert, Form, Input, InputNumber, Space, Spin } from "antd"
import axios from "axios"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { z } from "zod"
import { pairIdValidator, pairList } from "../../constants"
import { getPairStrip } from "../../helpers"
import { MinusStyled } from "../../styles"
import { ManualExposureDataType, PairId } from "../../types"
import { AuthContext } from "../auth-context"
import { changeSignToExposure, reshapeData } from "./helpers"
import * as S from "./styled"

const url =
  (process.env.REACT_APP_ENDPOINT || "http://localhost:4000") +
  "/settings/manual-exposure"

const submitValueValidator = z.object({
  exposure: z.array(
    z.object({ broker: z.string(), pair: pairIdValidator, volume: z.number() }),
  ),
})

const SettingsManualExposureCard = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { getCurrentUser } = useContext(AuthContext)
  const [form] = Form.useForm()
  const formRef = useRef(null)
  const [responseError, setResponseError] = useState<boolean>()
  const [responseSuccess, setResponseSuccess] = useState<boolean>()
  const [allData, setAllData] = useState<ManualExposureDataType>()

  const [addPairDisabled, setAddPairDisabled] = useState(false)
  const [pairsOptions, setPairsOptions] = useState<PairId[]>([])
  const [userInputPair, setUserInputPair] = useState<string>()
  const [userInputPairError, setUserInputPairError] = useState("")

  const getManualExposure = useCallback(
    () =>
      getCurrentUser().then(user => {
        if (!user.isLogged) return
        axios
          .get<ManualExposureDataType>(url, {
            headers: {
              Authorization: user.tokens.token,
            },
          })
          .then(({ data }) => {
            // NOTE WE ARE CHANGING SIGN HERE!!!!
            const newData = reshapeData(data)
            setAllData(newData)
            setPairsOptions(newData.pairs)
            setIsLoading(false)
          })
      }),
    [getCurrentUser],
  )

  useEffect(() => {
    setIsLoading(true)
    getManualExposure()
  }, [getManualExposure])

  const onFinish = async (values: unknown) => {
    setResponseSuccess(false)
    setResponseError(false)

    const parsed = submitValueValidator.safeParse(values)
    if (!parsed.success) {
      setResponseError(true)
      return
    }

    const user = await getCurrentUser()
    if (!user.isLogged) {
      setResponseError(true)
      return
    }
    const dataToSend = parsed.data.exposure.map(changeSignToExposure)
    if (!user.isLogged) return

    await axios
      .post(
        url,
        { data: dataToSend },
        {
          headers: {
            Authorization: user.tokens.token,
          },
        },
      )
      .then(_ => {
        setResponseSuccess(true)
      })
      .catch(err => {
        console.error(err)
        setResponseError(true)
      })
  }

  return (
    <S.Wrapper isLoading={isLoading}>
      {isLoading ? (
        <S.SpinnerWrapper>
          <Spin />
        </S.SpinnerWrapper>
      ) : (
        <div className="immovable">
          <S.TitleWrapper>
            <S.Title>Trades to flatten exposures</S.Title>
          </S.TitleWrapper>
          <S.FormStyled
            name="manualExposureForm"
            onFinish={onFinish}
            form={form}
            ref={formRef}
            requiredMark={false}
            initialValues={{ exposure: allData?.exposure }}
            autoComplete="off"
          >
            <Form.List name="exposure">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                    >
                      <S.FormItemStyled
                        {...restField}
                        name={[name, "broker"]}
                        rules={[{ required: true, message: "Missing broker" }]}
                      >
                        <S.SelectStyled
                          placeholder="BROKER"
                          options={allData?.popBrokers.map(broker => ({
                            value: broker,
                            label: broker,
                          }))}
                        />
                      </S.FormItemStyled>
                      <S.FormItemStyled
                        {...restField}
                        name={[name, "pair"]}
                        rules={[{ required: true, message: "Missing pair" }]}
                      >
                        <S.SelectStyled
                          placeholder="PAIR"
                          options={pairsOptions.map(pair => ({
                            value: pair,
                            label: getPairStrip(pair),
                          }))}
                          dropdownRender={menu => (
                            <>
                              {menu}
                              <S.AddPairWrapper>
                                <Input
                                  value={userInputPair}
                                  maxLength={6}
                                  onChange={event => {
                                    const candidatePair =
                                      event.target.value.toUpperCase()
                                    setUserInputPair(event.target.value)

                                    if (candidatePair.length !== 6) {
                                      setAddPairDisabled(true)
                                      setUserInputPairError(
                                        "Please input 6 characters",
                                      )
                                      return
                                    }

                                    const pairIdFound = pairList.find(
                                      ({ label }) => label === candidatePair,
                                    )
                                    if (pairIdFound) {
                                      setAddPairDisabled(false)
                                      setUserInputPairError("")
                                      return
                                    } else {
                                      setAddPairDisabled(true)
                                      setUserInputPairError("Pair not found!")
                                    }
                                  }}
                                  status={addPairDisabled ? "error" : ""}
                                />
                                <S.PlusIconButton
                                  disabled={!userInputPair || addPairDisabled}
                                  onClick={() => {
                                    if (!userInputPair) return

                                    const candidatePair =
                                      userInputPair.toUpperCase()
                                    const pairIdFound = pairList.find(
                                      ({ label }) => label === candidatePair,
                                    )
                                    if (!pairIdFound) return

                                    setUserInputPair("")
                                    setPairsOptions(oldVals => [
                                      ...new Set([...oldVals, pairIdFound.id]),
                                    ])
                                  }}
                                />
                              </S.AddPairWrapper>

                              {userInputPairError ? (
                                <S.AddPairErrorWrapper>
                                  <S.AddPairErrorMessage>
                                    {userInputPairError}
                                  </S.AddPairErrorMessage>
                                </S.AddPairErrorWrapper>
                              ) : null}
                            </>
                          )}
                        />
                      </S.FormItemStyled>
                      <S.FormItemStyled
                        {...restField}
                        name={[name, "volume"]}
                        rules={[
                          { required: true, message: "Missing volume" },
                          {
                            transform: x => Math.abs(x),
                            message: "Must be not zero",
                            min: 1,
                          },
                          {
                            message: "Must be an integer number",
                            validator: (_, value: number) =>
                              Number.isInteger(value)
                                ? Promise.resolve()
                                : Promise.reject(),
                          },
                        ]}
                      >
                        <InputNumber placeholder="VOLUME" controls={false} />
                      </S.FormItemStyled>
                      <MinusStyled onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <S.FormItemStyled>
                    <S.AddLineButton
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add new line
                    </S.AddLineButton>
                  </S.FormItemStyled>
                </>
              )}
            </Form.List>
            <S.ButtonStyled type="primary" htmlType="submit">
              Submit
            </S.ButtonStyled>
          </S.FormStyled>
          {responseSuccess ? (
            <Alert
              message="Data successfully updated. But it could take up to 10 minutes to be accepted."
              type="success"
            />
          ) : null}
          {responseError ? (
            <Alert message="Unfortunately an error occurred." type="error" />
          ) : null}
        </div>
      )}
    </S.Wrapper>
  )
}

export default SettingsManualExposureCard

import { Form } from "antd"
import styled from "styled-components"
import { ButtonStyled as LoginButton } from "../../pages/login/styled"
import { breakpointNum, colors, palette } from "../../styles"
import * as T from "../typography"

export const Wrapper = styled.div<{ isLoading: boolean }>`
  background-color: ${colors.iUltraDarkGrey};
  border-radius: 4px;
  width: min-content;
  min-width: 400px;
  height: 100%;
  min-height: 230px;
  padding: 15px;
  ${({ isLoading }) =>
    isLoading
      ? `display: flex;
      align-items: center;
      justify-content: center;`
      : ``}
  @media (max-width: ${breakpointNum.tablet - 1}px) {
    min-width: 338px;
  }
`

export const TitleWrapper = styled.div`
  text-align: center;
  width: 100%;
  padding-bottom: 8px;
  margin-bottom: 9px;
  color: ${colors.iWhite};
  border-bottom: 1px solid ${colors.iMediumGrey};
`

export const SpinnerWrapper = styled.div`
  .ant-spin .ant-spin-dot-holder {
    color: ${palette.primary};
  }
`

export const Title = styled(T.H3)``

export const FormScrollable = styled.div`
  overflow-x: auto;
`
export const FormStyled = styled(Form)`
  #marginForm {
    margin-top: 10px;
    clear: right;
    padding-top: 25px;
  }
`

export const Grid = styled.div<{ numCols: number }>`
  display: grid;
  grid-gap: 0.3em;
  grid-template-columns: 3fr repeat(${props => props.numCols}, 1fr);
`

export const GridRow = styled.div`
  display: contents;
  padding: 0.2em;
`

export const GridCell = styled.div`
  display: block;
  margin: 0.2em;
  color: ${colors.iWhite};
  min-width: 95px;
  white-space: nowrap;
  input {
    text-align: center;
  }
  .ant-form-item {
    margin: 0 0 10px;
  }
`

export const GridHeaderCell = styled(GridCell)`
  text-align: center;
`

export const FormItemStyled = styled(Form.Item)<{ $red?: boolean }>`
  .ant-form-item-label {
    text-align: left;
  }
  .ant-form-item-label label {
    font-size: 16px;
    color: ${colors.iWhite};
  }

  .ant-input-number {
    width: 100%;
    text-align: right;
    background-color: ${props => (props.$red ? colors.iRed : colors.iBlack)};
    border-radius: 2px;
    color: ${colors.iWhite};
    border: 1px solid ${colors.iBlack};
    input {
      text-align: right;
    }
  }

  .ant-input-number-input {
    color: ${colors.iWhite};
  }

  @media (max-width: 575px) {
    .ant-form .ant-form-item .ant-form-item-control,
    .ant-form .ant-form-item .ant-form-item-label {
      flex: 0 0 100%;
      max-width: 50% !important;
    }
  }
`

export const ButtonStyled = styled(LoginButton)`
  float: right;
  width: 89px;
  margin-right: 3px;
  margin-top: 20px;
`

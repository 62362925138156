import styled from "styled-components"
import { colors } from "../../styles"
import * as T from "../typography"

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-basis: auto;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
`

export const LeftContentWrapper = styled.div`
  padding: 8px;
  height: auto;
  display: flex;
  margin-bottom: auto;
  width: 100%;
  @media (max-width: 599px) {
    padding-bottom: 3em;
  }
`

export const Grid = styled.div`
  width: 100%;
  justify-content: space-around;
  flex-basis: auto;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  @media (max-width: 599px) {
    display: block;
  }
`

export const Row = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.iUltraLightGrey}25;
  padding: 0.2em;
`

export const RowBig = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.iUltraLightGrey}25;
  @media (max-width: 599px) {
    padding-bottom: 1em;
  }
`

export const LabelText = styled(T.P3)<{
  active?: boolean
  disabled?: boolean
}>`
  font-family: Lato-Bold;
  font-size: ${T.fontSizes.p3}px;
  @media (max-width: 599px) {
    font-size: ${T.fontSizes.p1}px;
    align-self: center;
  }
`

export const Label = styled(T.P3)`
  font-family: Lato-Bold;
  font-size: ${T.fontSizes.p3}px;
  @media (max-width: 599px) {
    font-size: 16px;
    align-self: center;
  }
`

export const Colored = styled(T.P3)<{
  isNegative: boolean
  isZero?: boolean
}>`
  min-width: 50px;
  color: ${props =>
    props.isZero
      ? colors.iWhite
      : props.isNegative
        ? colors.iRed
        : colors.iBrightGreen};
  text-align: right;
  font-family: Lato-Bold;
  font-size: ${T.fontSizes.p3}px;
  @media (max-width: 599px) {
    font-size: 20px;
  }
`

export const LabelBig = styled(T.P1)`
  font-family: Lato-Bold;
  font-size: 22px;
  margin-bottom: 5px;
  @media (max-width: 599px) {
    font-size: 32px;
    align-self: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
export const ColoredBig = styled(T.P1)<{
  isNegative: boolean
  isZero?: boolean
}>`
  min-width: 50px;
  color: ${props =>
    props.isZero
      ? colors.iWhite
      : props.isNegative
        ? colors.iRed
        : colors.iBrightGreen};
  text-align: right;
  font-size: 22px;
  font-family: Lato-Bold;
  @media (max-width: 599px) {
    font-size: 40px;
  }
`

export const DotsFlashing = styled.div`
  position: relative;
  margin-right: 10px;
  margin-top: 4px;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: ${colors.iWhite};
  color: ${colors.iWhite};
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
  ::before,
  ::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  ::before {
    left: -10px;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: ${colors.iWhite};
    color: ${colors.iWhite};
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }
  ::after {
    left: 10px;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: ${colors.iWhite};
    color: ${colors.iWhite};
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dot-flashing {
    0% {
      background-color: transparent;
    }
    50%,
    100% {
      background-color: ${colors.iWhite};
    }
  }
`

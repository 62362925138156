import { BaseOptionType, DefaultOptionType } from "antd/lib/select"
import { FilterFunc } from "rc-select/lib/Select"
import { useContext, useEffect, useMemo, useState } from "react"
import { getGraphOptions, getPairFromId, isBrokerValid } from "../../helpers"
import {
  Broker,
  GraphInfo,
  GraphsList,
  Pair,
  PairId,
  SubheaderOption,
} from "../../types"
import { PairsBrokersListContext } from "../used-pairs-brokers-context"
import { renderOptions } from "./helpers"
import * as S from "./styled"

type Props<Value extends any[]> = {
  graphs: GraphsList
  setGraphs: React.Dispatch<React.SetStateAction<GraphsList>>
  graphInfo: GraphInfo
  options?: SubheaderOption<Value[number]>[]
}

export const WidgetSubheader = <SelectValue extends any[]>({
  graphs,
  setGraphs,
  graphInfo,
  options,
}: Props<SelectValue>) => {
  const { allPairs, activePairs, brokersList, isPairValid } = useContext(
    PairsBrokersListContext,
  )
  const [pairLabel, setPairLabel] = useState<string | undefined>()
  useEffect(() => {
    setPairLabel(getPairFromId(graphInfo.pair).label)
  }, [graphInfo])

  const [selectablePairs, setSelectablePairs] = useState<Pair[]>(activePairs)
  const graphOptions = useMemo(
    () => getGraphOptions(graphInfo.type),
    [graphInfo.type],
  )

  useEffect(() => {
    if (!graphOptions) return
    setSelectablePairs(allPairs.filter(p => isPairValid(p.id, graphOptions)))
  }, [allPairs, isPairValid, graphOptions])

  const changePair = (newPair: PairId) => {
    if (graphInfo.pair === newPair) return

    setGraphs(
      graphs.map(g => {
        if (g.id !== graphInfo.id) return g

        return {
          ...g,
          ...graphInfo,
          pair: newPair,
        }
      }),
    )
  }

  const changeBroker = (newBroker: Broker) => {
    if (graphInfo.broker.id === newBroker.id) return

    setGraphs(
      graphs.map(g => {
        if (g.id !== graphInfo.id) return g
        return {
          ...g,
          ...graphInfo,
          broker: newBroker,
        }
      }),
    )
  }

  const pairSearch: FilterFunc<DefaultOptionType | BaseOptionType> = (
    input,
    option,
  ) =>
    (option!.children as unknown as string)
      .toLowerCase()
      .startsWith(input.toLowerCase())

  return (
    <S.SubHeaderWrapper>
      <S.LeftSubHeaderWrapper className="immovable">
        <S.SelectStyled
          filterOption={pairSearch}
          showSearch
          value={pairLabel}
          onChange={value => changePair(value as PairId)}
          onMouseDown={e => e.stopPropagation()}
          disabled={!selectablePairs.length}
        >
          {selectablePairs.map(pair => (
            <S.OptionStyled key={`option-${pair.id}`} value={pair.id}>
              {pair.label}
            </S.OptionStyled>
          ))}
        </S.SelectStyled>
        <S.SelectStyled
          showSearch
          value={graphInfo.broker.label}
          onChange={value =>
            changeBroker(JSON.parse(value as string) as Broker)
          }
          onMouseDown={e => e.stopPropagation()}
          disabled={
            !(
              !graphOptions ||
              graphOptions.brokers.tradedBrokers ||
              graphOptions.brokers.allIndividualFeeds ||
              graphOptions.brokers.allPOP
            ) && graphOptions.brokers.aggregated
          }
          options={
            graphOptions &&
            brokersList
              .filter(b => isBrokerValid(b, graphOptions))
              .map(broker => ({
                value: JSON.stringify(broker),
                label: broker.label,
              }))
          }
        />
      </S.LeftSubHeaderWrapper>
      <S.RightSubHeaderWrapper className="immovable">
        <S.RightOptionTogglesWrapper onMouseDown={e => e.stopPropagation()}>
          {options?.map(renderOptions)}
        </S.RightOptionTogglesWrapper>
      </S.RightSubHeaderWrapper>
    </S.SubHeaderWrapper>
  )
}

import { Check } from "@styled-icons/boxicons-regular/Check"
import { CloseOutline as Cross } from "@styled-icons/evaicons-outline/CloseOutline"
import { Clock } from "@styled-icons/fa-regular/Clock"
import styled from "styled-components"
import { ReactComponent as Hexagon } from "../../icons/hexagon.svg"
import { colors, palette } from "../../styles"
import * as T from "../typography"
import { Handshake } from "styled-icons/fa-regular"

export const Wrapper = styled.div<{ isLoading: boolean }>`
  background-color: ${colors.iUltraDarkGrey};
  border-radius: 4px;
  min-width: 500px;
  max-width: 650px;
  height: 100%;
  padding: 16px;
  overflow-x: auto;
  ${({ isLoading }) =>
    isLoading
      ? `display: flex;
      align-items: center;
      justify-content: center;`
      : ``}
  @media (max-width: 599px) {
    min-width: 200px;
  }
`

export const SpinnerWrapper = styled.div`
  .ant-spin .ant-spin-dot-holder {
    color: ${palette.primary};
  }
`

export const TitleMonitoringWrapper = styled.div`
  text-align: center;
  width: 100%;
  padding-bottom: 8px;
  margin-bottom: 9px;
  color: ${colors.iWhite};
  border-bottom: 1px solid ${colors.iMediumGrey};
`

export const Title = styled(T.H3)``

export const HeaderLabel = styled(T.H4)`
  color: ${colors.iWhite};
  text-align: center;
  margin: 0 0.2em 0.5em;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const Table = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 2fr 3fr;
  grid-row-gap: 0.1em;
  align-items: center;
  @media (max-width: 599px) {
    grid-template-columns: 1fr 2fr 1fr 2fr 2fr;
  }
`

export const Row = styled.div`
  display: contents;

  :not(.header):hover > * {
    background-color: ${colors.iDarkGrey};
    transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`

export const RowValueLabel = styled(T.P3)`
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${colors.iWhite};
  text-align: center;
  padding: 0.5em;
`

export const RowValueImg = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
`

export const HexagonStyled = styled(Hexagon)<{ $isUsed?: boolean }>`
  color: ${({ $isUsed }) => ($isUsed ? `${colors.iGreen}` : `${colors.iRed}`)};
  width: 12px;
`

export const HexagonColored = styled(Hexagon)<{ color: string }>`
  color: ${({ color }) => color};
  width: 12px;
`

export const NotesWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
`

export const StyledClock = styled(Clock)<{ color: string }>`
  color: ${({ color }) => color};
`

export const StyledCheck = styled(Check)`
  color: ${colors.iGreenText};
`

export const StyledCross = styled(Cross)`
  color: ${colors.iRedText};
`

export const HandshakeStyled = styled(Handshake)`
  color: ${colors.iOrange};
`

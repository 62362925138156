import { Form } from "antd"
import styled from "styled-components"
import { ButtonStyled as ButtonLogin } from "../../pages/login/styled"
import { colors, palette } from "../../styles"
import * as T from "../typography"

export const Wrapper = styled.div<{ isLoading: boolean }>`
  background-color: ${colors.iUltraDarkGrey};
  border-radius: 4px;
  width: min-content;
  min-width: 250px;
  height: 100%;
  min-height: 230px;
  padding: 15px;
  ${({ isLoading }) =>
    isLoading
      ? `display: flex;
      align-items: center;
      justify-content: center;`
      : ``}
`

export const TitleWrapper = styled.div`
  text-align: center;
  width: 100%;
  padding-bottom: 8px;
  margin-bottom: 9px;
  color: ${colors.iWhite};
  border-bottom: 1px solid ${colors.iMediumGrey};
`

export const Title = styled(T.H3)``

export const FormStyled = styled(Form)`
  #marginForm {
    margin-top: 10px;
    clear: right;
    padding-top: 25px;
  }
`

export const FormItemStyled = styled(Form.Item)`
  .ant-form-item-label {
    text-align: left;
  }
  .ant-form-item-label label {
    font-size: 16px;
    color: ${colors.iWhite};
  }
  .ant-input {
    text-align: right;
    background-color: ${colors.iBlack};
    color: ${colors.iWhite};
    border: 1px solid ${colors.iBlack};
  }
  .ant-input-number {
    width: 100%;
    text-align: right;
    border-radius: 2px;
    background-color: ${colors.iBlack};
    border: 1px solid ${colors.iBlack};
    input {
      text-align: right;
    }
  }

  .ant-input-number-input {
    color: ${colors.iWhite};
  }

  .ant-switch.ant-switch-checked {
    background: ${palette.primary};
  }
  .ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background: ${palette.primary};
  }

  @media (max-width: 575px) {
    .ant-form .ant-form-item .ant-form-item-control,
    .ant-form .ant-form-item .ant-form-item-label {
      flex: 0 0 100%;
      max-width: 50% !important;
    }
  }
`

export const ButtonStyled = styled(ButtonLogin)`
  float: right;
  width: 89px;
  margin-right: 3px;
  margin-top: 20px;
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 4fr 3fr;
  grid-row-gap: 0.3em;
`

export const GridHeader = styled.div`
  display: contents;
`
export const HeaderLabel = styled(T.H4)`
  color: ${colors.iWhite};
  margin: 0 0.2em 0.5em;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  :first-child {
    display: block;
  }
`
export const GridRow = styled.div`
  display: contents;
  padding: 0.2em;
`
export const GridCell = styled.div`
  display: block;
  padding: 0.2em;
  color: ${colors.iWhite};
  min-width: 95px;
  place-items: center;
  align-self: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  .ant-form-item {
    margin: 0 0 0;
  }
`

export const FormScrollable = styled.div`
  overflow-x: auto;
`

export const SpinnerWrapper = styled.div`
  .ant-spin .ant-spin-dot-holder {
    color: ${palette.primary};
  }
`

export const BottomText = styled(T.P3)`
  color: ${colors.iMediumGrey};
  text-align: right;
  width: 100%;
  margin-top: 1em;
`
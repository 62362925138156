import { Spin } from "antd"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { getColor } from "../../helpers"
import { POPIconStyled } from "../../styles"
import {
  GraphInfo,
  GraphReactComponent,
  WftBrokerOrPopTableData,
  WftBrokerOrPopTableTick,
} from "../../types"
import { PairsBrokersListContext } from "../used-pairs-brokers-context"
import WidgetHeader from "../widget-header"
import { WidgetSubheader } from "../widget-subheader"
import { WidgetWrapper } from "../widget-wrapper"
import {
  formatDealsWft,
  formatTableWft,
  formatVolumeWft,
  formatVolumeWftLabel,
  pointVolume,
} from "./helpers"
import * as S from "./styled"

const columnLabels = [
  "POP/BROKER",
  "DEALS",
  "VOLUME",
  "PNL",
  "COMMISSIONS",
  "GROSS",
]

export const PopOrBrokerTableWidget: GraphReactComponent = ({
  socket,
  broker,
  pair,
  graphs,
  setGraphs,
  id,
  vwap,
  comm,
}) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const [wftBrokerOrPopTableData, setWftBrokerOrPopTableData] = useState<
    WftBrokerOrPopTableData[]
  >([])
  const [modalityPOP, setModalityPOP] = useState(true)

  const wftBrokerOrPopTableListener = useCallback(
    (tick: WftBrokerOrPopTableTick) => {
      if (tick.brokerPair === "aggregated-all")
        setWftBrokerOrPopTableData(tick.data)
    },
    [],
  )

  const aggregatedData = useMemo(
    () =>
      wftBrokerOrPopTableData.find(
        ({ broker, pair }) => broker === "aggregated" && pair === "all",
      ),
    [wftBrokerOrPopTableData],
  )

  const unAggregatedData = useMemo(
    () =>
      wftBrokerOrPopTableData.filter(
        ({ broker, pair }) => broker !== "aggregated" && pair === "all",
      ),
    [wftBrokerOrPopTableData],
  )

  const graphInfo: GraphInfo = useMemo(
    () => ({
      broker,
      pair,
      type: "popOrBrokerTable",
      id,
      Graph: PopOrBrokerTableWidget,
      vwap,
      comm,
    }),
    [broker, comm, id, pair, vwap],
  )

  const { colorsMapper } = useContext(PairsBrokersListContext)

  useEffect(() => {
    if (!socket) return () => {}
    socket.on("popOrBrokerTable", wftBrokerOrPopTableListener)
    return () => socket.off("popOrBrokerTable", wftBrokerOrPopTableListener)
  }, [socket, wftBrokerOrPopTableListener])

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"))
    }, 10)
  }, [])

  return (
    <WidgetWrapper>
      <WidgetHeader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
        widgetTitle="Volume distribution"
      />
      <WidgetSubheader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
        options={[
          {
            type: "toggle",
            value: modalityPOP,
            onClick: () => {
              chartComponentRef.current?.chart.series[0].remove(false)
              setModalityPOP(!modalityPOP)
            },
            Icon: POPIconStyled,
            title: modalityPOP ? "switch to Brokers" : "switch to POP",
          },
        ]}
      />
      {wftBrokerOrPopTableData.length === 0 ? (
        <S.SpinnerWrapper>
          <Spin />
        </S.SpinnerWrapper>
      ) : (
        <>
          <HighchartsReact
            highcharts={Highcharts}
            containerProps={{
              className: "chartContainer",
              style: {
                height: "auto",
                width: "100%",
              },
            }}
            options={{
              chart: {
                backgroundColor: "transparent",
                height: 250,
              },
              dataLabels: {
                enabled: false,
              },
              title: {
                text: "Volume",
                style: {
                  color: "#fff",
                },
              },
              tooltip: {
                pointFormat: `{point.percentage:.0f}%<br>Volume: ${pointVolume}`,
              },
              borderColor: "transparent",
              plotOptions: {
                pie: {
                  animation: false,
                  dataLabels: {
                    enabled: false,
                    distance: 10,
                    style: {
                      textShadow: false,
                      textOutline: false,
                      color: "#fff",
                    },
                    format: "{point.name} {point.percentage:.0f}%",
                  },
                },
              },
              colors: unAggregatedData.map(({ broker }) =>
                getColor(colorsMapper, broker),
              ),
              series: [
                {
                  type: "pie",
                  name: "Volume",
                  dataLabels: {
                    enabled: true,
                    inside: true,
                  },
                  borderColor: "transparent",
                  data: unAggregatedData.map(
                    ({ broker, volume, brokerType }) => {
                      const isPopRow = modalityPOP && brokerType !== "PartOfPop"
                      const isBrokerRow = !modalityPOP && brokerType !== "Pop"
                      if (!isPopRow && !isBrokerRow) return ""
                      else
                        return {
                          y: formatVolumeWft(volume),
                          name: broker,
                        }
                    },
                  ),
                },
              ],
            }}
            ref={chartComponentRef}
          />
          <S.GridWrapper>
            {columnLabels.map((label, index) => {
              return (
                <S.GridHeader key={label}>
                  {index === 0 ? (modalityPOP ? "POP" : "BROKER") : label}
                </S.GridHeader>
              )
            })}
            {unAggregatedData.map(
              (
                {
                  broker,
                  numberOfDeals,
                  volume,
                  pnlUSD,
                  commissions,
                  gross,
                  brokerType,
                },
                index,
              ) => {
                const isPopRow = modalityPOP && brokerType !== "PartOfPop"
                const isBrokerRow = !modalityPOP && brokerType !== "Pop"

                if (!isPopRow && !isBrokerRow) return ""
                else
                  return (
                    <React.Fragment key={index}>
                      <S.CellLeft>{broker}</S.CellLeft>
                      <S.CellCenter>
                        {formatDealsWft(numberOfDeals)}
                      </S.CellCenter>
                      <S.CellRight>{formatVolumeWftLabel(volume)}</S.CellRight>
                      <S.CellRight>
                        {isPopRow ? formatTableWft(pnlUSD) : "n/a"}
                      </S.CellRight>
                      <S.CellRight>{formatTableWft(commissions)}</S.CellRight>
                      <S.CellRight>
                        {isPopRow ? formatTableWft(gross) : "n/a"}
                      </S.CellRight>
                    </React.Fragment>
                  )
              },
            )}
            <React.Fragment key="totals">
              <S.CellTotalLeft>CONSOLIDATED</S.CellTotalLeft>
              <S.CellTotalCenter>
                {formatDealsWft(aggregatedData?.numberOfDeals ?? -1)}
              </S.CellTotalCenter>
              <S.CellTotalRight>
                {formatVolumeWftLabel(aggregatedData?.volume ?? -1)}
              </S.CellTotalRight>
              <S.CellTotalRight>
                {modalityPOP
                  ? formatTableWft(aggregatedData?.pnlUSD ?? -1)
                  : "n/a"}
              </S.CellTotalRight>
              <S.CellTotalRight>
                {formatTableWft(aggregatedData?.commissions ?? -1)}
              </S.CellTotalRight>
              <S.CellTotalRight>
                {modalityPOP
                  ? formatTableWft(aggregatedData?.gross ?? -1)
                  : "n/a"}
              </S.CellTotalRight>
            </React.Fragment>
          </S.GridWrapper>
        </>
      )}
    </WidgetWrapper>
  )
}

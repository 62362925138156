import { Alert, Form, Input, InputNumber, Popconfirm, Space, Spin } from "antd"
import axios from "axios"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { z } from "zod"
import { pairIdValidator, pairList } from "../../constants"
import { getPairStrip } from "../../helpers"
import { BrokerId, PairId, PlaceManualOrdersDataType } from "../../types"
import { AuthContext } from "../auth-context"
import { transformDateTime } from "./helpers"
import * as S from "./styled"


const url =
  (process.env.REACT_APP_ENDPOINT || "http://localhost:4000") +
  "/settings/place-manual-order"

const submitValueValidator = z.object({
  broker: z.string(),
  volume: z.number(),
  pair: pairIdValidator,
})

const SettingsManualInventoryCard = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { getCurrentUser } = useContext(AuthContext)
  const [form] = Form.useForm()
  const formRef = useRef(null)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)


  const [responseError, setResponseError] = useState<string>("")
  const [responseSuccess, setResponseSuccess] = useState<string>("")

  const [addPairDisabled, setAddPairDisabled] = useState(false)
  const [pairsOptions, setPairsOptions] = useState<PairId[]>([])
  const [brokersOptions, setBrokersOptions] = useState<BrokerId[]>([])

  const [userInputPair, setUserInputPair] = useState<string>()
  const [userInputPairError, setUserInputPairError] = useState("")

  const getBrokersPairsOptions = useCallback(
    () =>
      getCurrentUser().then(user => {
        if (!user.isLogged) return
        axios
          .get<PlaceManualOrdersDataType>(url, {
            headers: {
              Authorization: user.tokens.token,
            },
          })
          .then(({ data }) => {
            setPairsOptions(data.pairs)
            setBrokersOptions(data.popBrokers)
          })
          .catch(err => {
            console.error(err)
            if (err.response.statusText)
              setResponseError(err.response.statusText)
            else if (err.response.data) setResponseError(err.response.data)
            else if (err.errorMessage) setResponseError(err.errorMessage)
          })
          .finally(() => setIsLoading(false))
      }),
    [getCurrentUser],
  )

  useEffect(() => {
    setIsLoading(true)
    getBrokersPairsOptions()
  }, [getBrokersPairsOptions])

  const onFinish = async (values: unknown) => {
    setResponseSuccess("")
    setResponseError("")

    const parsed = submitValueValidator.safeParse(values)
    if (!parsed.success) {
      setResponseError("Data to be sent is not correct")
      return
    }

    const user = await getCurrentUser()
    if (!user.isLogged) {
      setResponseError("User is not logged")
      return
    }

    if (!user.isLogged) return

    await axios
      .post(
        url,
        { data: values },
        {
          headers: {
            Authorization: user.tokens.token,
          },
        },
      )
      .then(res => {
        const { broker, volume, pair, timestamp } = res.data
        const formattedTS = transformDateTime(timestamp)
        const formattedPair = pairList.find(x => x.id === pair)?.label
        if (res.data.status === "filled") {
          setResponseSuccess(`Order for <i>${formattedPair}</i> and volume <i>${volume}</i> with <i>${broker}</i> has been <strong>SUCCESSFULLY</strong> placed at ${formattedTS}`)
          return
        }
        else if (res.data.status === "partiallyFilled") {
          setResponseError(`Order for <i>${formattedPair}</i> and volume <i>${volume}</i> with <i>${broker}</i> has been <strong>PARTIALLY FILLED</strong>  at ${formattedTS}`)
          return
        }
        else if (res.data.status === "failed") {
          setResponseError(`Order for <i>${formattedPair}</i> and volume <i>${volume}</i> with <i>${broker}</i> has been <strong>CANCELLED</strong> at ${formattedTS}`)
          return
        }
        else setResponseError("Something wrong with the status")
      })
      .catch(err => {
        console.error(err)
        if (err.response.statusText)
          setResponseError(err.response.statusText)
        else if (err.response.data) setResponseError(err.response.data)
        else if (err.errorMessage) setResponseError(err.errorMessage)
      })
      .finally(() => { form.resetFields() })
  }

  return (
    <S.Wrapper isLoading={isLoading}>
      {isLoading ? (
        <S.SpinnerWrapper>
          <Spin />
        </S.SpinnerWrapper>
      ) : (
        <>
          <S.TitleWrapper>
            <S.Title>Inventory Deals</S.Title>
          </S.TitleWrapper>
          <S.FormStyled
            name="manualInventory"
            onFinish={onFinish}
            form={form}
            ref={formRef}
            requiredMark={false}
            autoComplete="off"
            onFieldsChange={(_, allFields) => {
              const hasValidationErrors = allFields.some(field => (field?.errors ?? []).length > 0)

              const requiredFields = ["broker", "pair", "volume"]
              const hasEmptyRequiredFields = requiredFields.some(
                field => !form.getFieldValue(field)
              );
              setIsSubmitDisabled(hasValidationErrors || hasEmptyRequiredFields)
            }}
          >
            <Space
              style={{ display: "flex", marginBottom: 8 }}
              align="baseline"
            >
              <S.FormItemStyled
                name="broker"
                rules={[{ required: true, message: "Missing broker" }]}
              >
                <S.SelectStyled
                  placeholder="BROKER"
                  options={brokersOptions.map(broker => ({
                    value: broker,
                    label: broker,
                  }))}
                />
              </S.FormItemStyled>
              <S.FormItemStyled
                name="pair"
                rules={[{ required: true, message: "Missing pair" }]}
              >
                <S.SelectStyled
                  placeholder="PAIR"
                  options={pairsOptions.map(pair => ({
                    value: pair,
                    label: getPairStrip(pair),
                  }))}
                  dropdownRender={menu => (
                    <>
                      {menu}
                      <S.AddPairWrapper>
                        <Input
                          value={userInputPair}
                          maxLength={8}
                          onChange={event => {
                            const candidatePair =
                              event.target.value.toUpperCase()
                            setUserInputPair(event.target.value)

                            if (!(candidatePair.length >=6 && candidatePair.length <=8) ) {
                              setAddPairDisabled(true)
                              setUserInputPairError(
                                "Pairs can have 6, 7 or 8 characters",
                              )
                              return
                            }

                            const pairIdFound = pairList.find(
                              ({ label }) => label === candidatePair,
                            )
                            if (pairIdFound) {
                              setAddPairDisabled(false)
                              setUserInputPairError("")
                              return
                            } else {
                              setAddPairDisabled(true)
                              setUserInputPairError("Pair not found!")
                            }
                          }}
                          status={addPairDisabled ? "error" : ""}
                        />
                        <S.PlusIconButton
                          disabled={!userInputPair || addPairDisabled}
                          onClick={() => {
                            if (!userInputPair) return

                            const candidatePair =
                              userInputPair.toUpperCase()
                            const pairIdFound = pairList.find(
                              ({ label }) => label === candidatePair,
                            )
                            if (!pairIdFound) return

                            setUserInputPair("")
                            setPairsOptions(oldVals => [
                              ...new Set([...oldVals, pairIdFound.id]),
                            ])
                          }}
                        />
                      </S.AddPairWrapper>

                      {userInputPairError ? (
                        <S.AddPairErrorWrapper>
                          <S.AddPairErrorMessage>
                            {userInputPairError}
                          </S.AddPairErrorMessage>
                        </S.AddPairErrorWrapper>
                      ) : null}
                    </>
                  )}
                />
              </S.FormItemStyled>
              <S.FormItemStyled
                name="volume"
                rules={[
                  { required: true, message: "Missing volume" },
                  {
                    validator: (_, value: number) =>
                      Math.abs(value) > 1e-5
                        ? Promise.resolve()
                        : Promise.reject(),
                    message: "Must be not zero",
                  },
                ]}
              >
                <InputNumber placeholder="VOLUME" controls={false} formatter={value => {
                  if (value === undefined) return "0"
                  return new Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 4,
                    minimumFractionDigits: 0,
                  }).format(Number(value))
                }} />
              </S.FormItemStyled>
            </Space>
            <Popconfirm
              title="Confirm Submission"
              description="Are you sure you want to submit the order?"
              onConfirm={() => form.submit()}
              onCancel={() => form.resetFields()}
              getPopupContainer={trigger => (trigger.parentNode as HTMLElement)}
              placement="bottom"
            >
              <S.ButtonStyled type="primary" disabled={isSubmitDisabled}>
                Submit
              </S.ButtonStyled>
            </Popconfirm>


          </S.FormStyled>
          {responseSuccess ? (
            <Alert
              message={
                <p dangerouslySetInnerHTML={{ __html: responseSuccess }} />}
              type="success"
            />
          ) : null}
          {responseError ? (
            <Alert message={
              <p dangerouslySetInnerHTML={{ __html: responseError }} />} type="error" />
          ) : null}
        </>
      )}
    </S.Wrapper>
  )
}

export default SettingsManualInventoryCard

import styled from "styled-components"
import { colors } from "../../styles"
import * as T from "../typography"

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-basis: auto;
  overflow: hidden;
  @media (max-width: 599px) {
    flex-direction: column;
  }
`

export const LeftContentWrapper = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 599px) {
    width: 100%;
    padding-bottom: 3em;
  }
`

export const RightContentWrapper = styled.div`
  padding-right: 5px;
  flex-basis: auto;
  overflow: hidden;
  flex-grow: 1;
  @media (max-width: 599px) {
    width: 100% !important;
    height: 40vh !important;
    margin-top: 20px;
  }
`

export const Grid = styled.div`
  width: 100%;
  justify-content: space-around;
  flex-basis: auto;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  @media (max-width: 599px) {
    display: block;
  }
`

export const Row = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.iUltraLightGrey}25;
  align-items: center;
  gap: 50px;
`

export const Colored = styled(T.P3)<{
  isHigh: boolean
  isZero?: boolean
}>`
  min-width: 50px;
  color: ${props =>
    props.isZero
      ? colors.iWhite
      : props.isHigh
        ? colors.iRed
        : colors.iBrightGreen};
  text-align: right;
  font-family: Lato-Bold;
  font-size: ${T.fontSizes.p3}px;
  @media (max-width: 599px) {
    font-size: 20px;
  }
`

export const BrokerDotWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const Value = styled(T.P3)`
  min-width: 50px;
  color: ${colors.iWhite};
  font-family: Lato-Bold;
  font-size: ${T.fontSizes.p3}px;
  @media (max-width: 599px) {
    font-size: 20px;
  }
`

export const Dot = styled.div<{
  color?: string
}>`
  color: ${colors.iWhite};
  ${({ color }) =>
    color &&
    `
  margin-bottom: 0.2em;
  margin-right: 8px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  float: left;
  background-color: ${color};
  content: "";
  align-self: center;
  `}
  @media (max-width: 599px) {
    font-size: 20px;
    :after {
      height: 20px;
      width: 20px;
      align-self: center;
      margin-top: 5px;
    }
  }
`

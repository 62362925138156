import { Input, Space } from "antd"
import type { ColumnType } from "antd/es/table"
import { FilterValue } from "antd/es/table/interface"
import axios from "axios"
import React from "react"
import { pairList } from "../../constants"
import { getPairFromId } from "../../helpers"
import { colors } from "../../styles"
import { Currency, OrderBlotterDeal, OrderBlotterParsedDeal } from "../../types"
import { SearchIcon, StyledButton } from "./styled"

const capitalizeCurrency = (currency: Currency): Uppercase<Currency> =>
  currency.toUpperCase() as Uppercase<Currency>

const capitalizeFirstLetter = (oper: string) =>
  `${oper.charAt(0).toUpperCase()}${oper.slice(1)}`

const capitalizeFirstLetterBuySell = (oper: "buy" | "sell"): "Buy" | "Sell" =>
  capitalizeFirstLetter(oper) as "Buy" | "Sell"

const excludeFiltersInternalId = (x: boolean | React.Key) =>
  x !== "resubmittedDeals" &&
  x !== "fakeDeals" &&
  x !== "manualDeals" &&
  x !== "arbitrageDeals" &&
  x !== "inventoryDeals"

const parseDeal = (deal: OrderBlotterDeal): OrderBlotterParsedDeal => ({
  ...deal,
  pair: getPairFromId(deal.pair).label,
  sent_time: new Intl.DateTimeFormat("en-GB", {
    day: "numeric",
    month: "numeric",
    year: "numeric",

    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    fractionalSecondDigits: 3,
  }).format(deal.sent_time),
  currency: capitalizeCurrency(deal.currency),
  operation: capitalizeFirstLetterBuySell(deal.operation),
  rate: deal.rate.toFixed(getPairFromId(deal.pair).bpPrecision || 5),
})

const pairToPairId = (filterParams: Record<string, FilterValue | null>) => {
  const { pair } = filterParams
  if (!pair) return filterParams
  return {
    ...filterParams,
    pair: pair.map(
      pairLabel => pairList.find(({ label }) => label === pairLabel)?.id,
    ),
  } as Record<string, FilterValue | null>
}

const handleInternalIDFilters = (
  filterParams: Record<string, FilterValue | null>,
) => {
  const { internal_id, ...rest } = filterParams
  if (!internal_id) return rest
  const searchParams = internal_id?.filter(excludeFiltersInternalId)
  return {
    ...rest,
    ...(searchParams?.length > 0 ? { internal_id: searchParams } : {}),
    ...(internal_id.includes("resubmittedDeals")
      ? { resubmitted_deal: "ok" }
      : {}),
    ...(internal_id.includes("fakeDeals") ? { deal_type: "fake" } : {}),
    ...(internal_id.includes("manualDeals") ? { deal_type: "manual" } : {}),
    ...(internal_id.includes("inventoryDeals") ? { deal_type: "inventory" } : {}),
    ...(internal_id.includes("arbitrageDeals")
      ? { deal_type: "arbitrage" }
      : {}),
  } as Record<string, FilterValue | null>
}

const reshapeFilters = (filters: [Record<string, FilterValue | null>]) =>
  filters
    .map(pairToPairId)
    .map(handleInternalIDFilters)
    .map(x => JSON.stringify(x))
    .at(0) || "{}"

export const getFilteredOrderBlotterData = (
  token: string,
  filters: Record<string, FilterValue | null>,
  cursor?: { id: number; eldestDate: number },
): Promise<OrderBlotterParsedDeal[]> =>
  axios
    .get<OrderBlotterDeal[]>(
      `${process.env.REACT_APP_ENDPOINT}/order-blotter`,
      {
        headers: {
          Authorization: token,
        },
        params: {
          filterParams: reshapeFilters([filters]),
          ...(cursor
            ? { cursor: cursor.id, oldestTimestamp: cursor.eldestDate }
            : {}),
        },
      },
    )
    .then(res =>
      res.data.sort((a, b) => b.sent_time - a.sent_time).map(parseDeal),
    )

export const getColumnSearchProps = (
  dataIndex: keyof Omit<OrderBlotterParsedDeal, "id" | "saved_at">,
  appliedFilters: Record<string, FilterValue | null>,
  additionalFilters?: { text: string; value: string }[],
  emptyFn?: () => void
): ColumnType<OrderBlotterParsedDeal> => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => {
    const searchKeys = selectedKeys.filter(excludeFiltersInternalId)
    const filtersKeys = selectedKeys.filter(x =>
      [
        "resubmittedDeals",
        "fakeDeals",
        "manualDeals",
        "arbitrageDeals",
        "inventoryDeals"
      ].includes(x.toString()),
    )
    return (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={searchKeys[0]}
          onChange={e => {
            const newKeys = e.target.value
              ? [...filtersKeys, e.target.value]
              : [...filtersKeys]
            setSelectedKeys(newKeys)
            confirm({ closeDropdown: false })
          }}
          onPressEnter={() => confirm()}
          style={{ marginBottom: 8, display: "block" }}
        />

        {additionalFilters && (
          <div style={{ marginBottom: 8 }}>
            {additionalFilters.map(filter => (
              <div key={filter.value}>
                <input
                  type="checkbox"
                  checked={selectedKeys.includes(filter.value)}
                  onChange={e => {
                    const newKeys = e.target.checked
                      ? [
                        ...selectedKeys.filter(excludeFiltersInternalId),
                        filter.value,
                      ]
                      : selectedKeys.filter(key => key !== filter.value)

                    if (emptyFn) emptyFn()
                    setSelectedKeys(newKeys)
                    confirm({ closeDropdown: false })
                  }}
                />
                <label style={{ marginLeft: 8, color: colors.iWhite }}>
                  {filter.text}
                </label>
              </div>
            ))}
          </div>
        )}

        <Space>
          <StyledButton
            type="primary"
            icon={<SearchIcon />}
            size="small"
            onClick={() => confirm()}
          >
            <span className={"btn-label"}> Search</span>
          </StyledButton>
          <StyledButton
            onClick={() => {
              clearFilters && clearFilters()
              confirm()
            }}
            size="small"
          >
            Reset
          </StyledButton>
        </Space>
      </div>
    )
  },
  filterIcon: (filtered: boolean) => <SearchIcon filtered={filtered} />,
  onFilter: (value, record) => {
    // if we have more additional filters (manualDeals, resubmittedDeals, fakeDeals....) apply all of them
    const searchFilters = appliedFilters?.internal_id
    if (additionalFilters && searchFilters) {
      return searchFilters.every(filterStr => {
        if (filterStr === "resubmittedDeals")
          return record[dataIndex].toString().endsWith("R")
        else if (filterStr === "manualDeals")
          return record[dataIndex].toString().includes("_m_")
        else if (filterStr === "fakeDeals")
          return record[dataIndex].toString().includes("_f_")
        else if (filterStr === "arbitrageDeals")
          return record[dataIndex].toString().includes("_a_")
        else if (filterStr === "inventoryDeals")
          return record[dataIndex].toString().includes("_i_")
        else
          return record[dataIndex]
            .toString()
            .toLowerCase()
            .startsWith((filterStr as string).toLowerCase().trim())
      })
      // else usual flow with the startsWith
    } else
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .startsWith((value as string).toLowerCase().trim())
  },
})


export const filterAndPrependIncomingDeals = (
  order: OrderBlotterDeal,
  dataSource: OrderBlotterParsedDeal[],
  filterParams: Record<string, FilterValue | null>,
) => {
  const parsedDeal = parseDeal(order)
  const filteredDeal = [parsedDeal]
    .filter(filterByInternalId(filterParams))
    .filter(filterByDealId(filterParams))
    .filter(filterByBroker(filterParams))
    .filter(filterByPair(filterParams))
    .filter(filterByOperation(filterParams))
    .filter(filterManualDeals(filterParams))
    .filter(filterFakeDeals(filterParams))
    .filter(filterResubmittedDeals(filterParams))
    .filter(filterArbitrageDeals(filterParams))
    .filter(filterInventoryDeals(filterParams))
  return [...filteredDeal, ...dataSource]
}

const filterByInternalId =
  (filterParams: Record<string, FilterValue | null>) =>
    ({ deal_id }: OrderBlotterParsedDeal) =>
      filterParams?.deal_id
        ? filterParams?.deal_id.some(filterKey =>
          deal_id.startsWith(filterKey as string),
        )
        : true

const filterManualDeals =
  (filterParams: Record<string, FilterValue | null>) =>
    ({ deal_id }: OrderBlotterParsedDeal) =>
      filterParams?.internal_id?.includes("manualDeals")
        ? deal_id.includes("_m_")
        : true

const filterFakeDeals =
  (filterParams: Record<string, FilterValue | null>) =>
    ({ deal_id }: OrderBlotterParsedDeal) =>
      filterParams?.internal_id?.includes("fakeDeals")
        ? deal_id.includes("_f_")
        : true

const filterArbitrageDeals =
  (filterParams: Record<string, FilterValue | null>) =>
    ({ deal_id }: OrderBlotterParsedDeal) => (
      process.env.REACT_APP_INSTANCE_TYPE === "crypto" &&
      filterParams?.internal_id?.includes("arbitrageDeals")
    )
      ? deal_id.includes("_a_")
      : true

const filterInventoryDeals =
  (filterParams: Record<string, FilterValue | null>) =>
    ({ deal_id }: OrderBlotterParsedDeal) => (
      process.env.REACT_APP_INSTANCE_TYPE === "crypto" &&
      filterParams?.internal_id?.includes("inventoryDeals")
    )
      ? deal_id.includes("_i_")
      : true

const filterResubmittedDeals =
  (filterParams: Record<string, FilterValue | null>) =>
    ({ deal_id }: OrderBlotterParsedDeal) =>
      filterParams?.internal_id?.includes("resubmittedDeals")
        ? deal_id.endsWith("R")
        : true

const filterByDealId =
  (filterParams: Record<string, FilterValue | null>) =>
    ({ internal_id }: OrderBlotterParsedDeal) =>
      filterParams?.internal_id?.filter(excludeFiltersInternalId)?.length
        ? filterParams?.internal_id.some(filterKey =>
          internal_id.startsWith(filterKey as string),
        )
        : true

const filterByBroker =
  (filterParams: Record<string, FilterValue | null>) =>
    ({ broker }: OrderBlotterParsedDeal) =>
      filterParams?.broker ? filterParams?.broker.includes(broker) : true

const filterByPair =
  (filterParams: Record<string, FilterValue | null>) =>
    ({ pair }: OrderBlotterParsedDeal) =>
      filterParams?.pair ? filterParams?.pair.includes(pair) : true

const filterByOperation =
  (filterParams: Record<string, FilterValue | null>) =>
    ({ operation }: OrderBlotterParsedDeal) =>
      filterParams?.operation ? filterParams?.operation.includes(operation) : true

export const transformSentDateTime = (value: string) => {
  const formattedDate = value.split("/")
  const eventDate = new Date(
    [formattedDate[1], formattedDate[0], formattedDate[2]].join("/"),
  )
  return new Date(eventDate.getTime())
    .toISOString()
    .replace(/T/, "_")
    .replace(/-/g, ".")
    .replace(/Z/, "")
}

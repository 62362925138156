import { Alert, Form, Input, InputNumber, Spin } from "antd"
import axios from "axios"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { typedEntries } from "../../helpers"
import { CurrencyUppercase, ExposureMarginDataType } from "../../types"
import { AuthContext } from "../auth-context"
import {
  filterOutTierInputs,
  getUniqueCurrencies,
  isArrayAscending,
} from "./helpers"
import * as S from "./styled"

const url =
  (process.env.REACT_APP_ENDPOINT || "http://localhost:4000") +
  "/settings/margins"

// the last index appears twice so we can modify both the last and previous to last element at the same time
const numberOfTiers = [0, 1, 2, 2] as const

const SettingsMarginsCard = () => {
  const [form] = Form.useForm()
  const formRef = useRef(null)
  const { getCurrentUser } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [allData, setAllData] = useState<ExposureMarginDataType>()
  const [allCurrencies, setAllCurrencies] = useState<CurrencyUppercase[]>([])
  const [selectedBroker, setSelectedBroker] = useState<string>("")
  const [responseError, setResponseError] = useState<string>("")
  const [responseSuccess, setResponseSuccess] = useState<boolean>(false)

  const getMarginsData = useCallback(
    () =>
      getCurrentUser()
        .then(user => {
          if (!user.isLogged) return
          axios
            .get<ExposureMarginDataType>(url, {
              headers: {
                Authorization: user.tokens.token,
              },
            })
            .then(({ data }) => {
              setAllData(data)
              const uniqueCurrencies = getUniqueCurrencies(data)
              setAllCurrencies(uniqueCurrencies)
              setIsLoading(false)
              setSelectedBroker(data.popBrokers[0])
            })
        })
        .catch(console.error),
    [getCurrentUser],
  )

  useEffect(() => {
    setIsLoading(true)
    getMarginsData()
  }, [getMarginsData])

  useEffect(() => {
    if (!formRef.current) return
    const formDefaults: Record<`${CurrencyUppercase}-${number}`, number> =
      allCurrencies.reduce((acc, currency) => {
        const currencyData =
          allData?.popConf && allData?.popConf[selectedBroker]
            ? allData.popConf[selectedBroker].margins[currency]
            : undefined
        if (currencyData === undefined)
          return {
            ...acc,
            [`${currency}-0`]: 0.01,
            [`${currency}-1`]: 0.01,
            [`${currency}-2`]: 0.01,
            [`${currency}-3`]: 0.01,
            [`tierinput-0`]: 10,
            [`tierinput-1`]: 50,
            [`tierinput-2`]: 100,
          }

        return {
          ...acc,
          ...currencyData.reduce(
            (acc2, [tier, margin], index) => ({
              ...acc2,
              [`${currency}-${index}`]: margin,
              [`tierinput-${index}`]: tier,
            }),
            {},
          ),
        }
      }, {})

    form.setFieldsValue(formDefaults)
  }, [selectedBroker, form, allCurrencies, allData?.popConf])

  const onFinish = async (values: any) => {
    setResponseSuccess(false)
    setResponseError("")

    const tierLevel = [
      Number(values["tierinput-0"]),
      Number(values["tierinput-1"]),
      Number(values["tierinput-2"]),
      Number(values["tierinput-2"]),
    ]

    const isArrayValid = isArrayAscending(tierLevel)
    if (!isArrayValid) {
      setResponseError("Tier list is not valid. It must be incremental!")
      return
    }

    const dataToSend = {
      popConf: typedEntries(values)
        .filter(filterOutTierInputs)
        .reduce(
          (acc, valueArray) => {
            const extendedKeys = (valueArray[0] as string).split("-") // -> AUD-0
            const extendedValue = Number(valueArray[1]) // currency margin -> 0.01
            const currencyKey = extendedKeys[0] as CurrencyUppercase // Currency --> AUD
            const levelIndex = Number(extendedKeys[1])
            return {
              ...acc,
              margins: {
                ...acc.margins,
                [currencyKey]: [
                  ...(acc.margins?.[currencyKey] ?? []),
                  [tierLevel[levelIndex], extendedValue],
                ],
              },
            }
          },
          { broker: selectedBroker, margins: {} } as {
            broker: string
            margins: Record<CurrencyUppercase, [tier: number, margin: number][]>
          },
        ),
    }

    const user = await getCurrentUser()
    if (!user.isLogged) return

    await axios
      .post(url, dataToSend, {
        headers: {
          Authorization: user.tokens.token,
        },
      })
      .then(res => {
        if (allData) {
          setResponseSuccess(true)
          setResponseError("")
          const newAllData = {
            ...allData,
            popConf: res.data,
          }
          setAllData(newAllData)
          const uniqueCurrencies = getUniqueCurrencies(newAllData)
          setAllCurrencies(uniqueCurrencies)
          setSelectedBroker(selectedBroker)
        } else throw new Error("Something went wrong in the BE call")
      })
      .catch(err => {
        console.error(err)
        setResponseError("Unfortunately an error occurred")
      })
  }

  return (
    <S.Wrapper isLoading={isLoading}>
      {isLoading ? (
        <S.SpinnerWrapper>
          <Spin />
        </S.SpinnerWrapper>
      ) : (
        <div className="immovable">
          <S.TitleWrapper>
            <S.Title>Margins Configuration</S.Title>
          </S.TitleWrapper>
          <S.BrokersSelectContainerDiv>
            <S.BrokersLabel>POP Broker </S.BrokersLabel>
            <S.SelectStyled
              value={selectedBroker}
              onChange={value => {
                if (!value || typeof value !== "string") return
                setSelectedBroker(value)
              }}
              onMouseDown={e => e.stopPropagation()}
              disabled={false}
              options={allData?.popBrokers.map(broker => ({
                value: broker,
                label: broker,
              }))}
            />
          </S.BrokersSelectContainerDiv>
          <S.FormHeader>Margin tiers</S.FormHeader>
          <S.FormScrollable>
            <S.FormStyled
              name="marginForm"
              layout={"horizontal"}
              onFinish={onFinish}
              form={form}
              ref={formRef}
              requiredMark={false}
            >
              <S.Grid>
                <S.GridHeader key="firstheader">
                  <S.HeaderLabel key={`PopBroker-header`}>&nbsp;</S.HeaderLabel>
                  {numberOfTiers.map((label, tierindex) => (
                    <S.GridCell key={`${label}-${tierindex}-item`}>
                      <S.FormItemTierStyled
                        label={null}
                        key={`formitem${label}-${tierindex}`}
                        name={`tierinput-${label}`}
                        colon={false}
                        rules={[
                          {
                            required: true,
                            message: "Please input a correct tier!",
                          },
                        ]}
                      >
                        <InputNumber
                          addonBefore={tierindex === 3 ? ">" : "≤"}
                          addonAfter="M"
                          controls={false}
                          id={`tierinput-${tierindex}`}
                          formatter={value => {
                            if (value === undefined) return "0"
                            return new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                              maximumFractionDigits: 0,
                              minimumFractionDigits: 0,
                            }).format(Number(value))
                          }}
                        />
                      </S.FormItemTierStyled>
                    </S.GridCell>
                  ))}
                </S.GridHeader>
                {allCurrencies &&
                  allCurrencies.map((currency, indexcur) => (
                    <S.GridRow key={`${indexcur}`}>
                      <S.GridCell key={`${indexcur}-row`}>
                        {currency}
                      </S.GridCell>
                      {numberOfTiers.map((element, i) => (
                        <S.GridCell key={`${element}-${i}-item`}>
                          <S.FormItemStyled
                            label={null}
                            key={`${currency}-${element}-${i}`}
                            name={`${currency}-${i}`}
                            colon={false}
                            rules={[
                              {
                                required: true,
                                message: "Please input a correct margin!",
                              },
                            ]}
                          >
                            <Input />
                          </S.FormItemStyled>
                        </S.GridCell>
                      ))}
                    </S.GridRow>
                  ))}
              </S.Grid>
              <S.FormItemStyled {...{ wrapperCol: { span: 24, offset: 0 } }}>
                <S.ButtonStyled type="primary" htmlType="submit">
                  Submit
                </S.ButtonStyled>
              </S.FormItemStyled>
            </S.FormStyled>
          </S.FormScrollable>
          {responseSuccess ? (
            <Alert message="Data successfully updated!" type="success" />
          ) : null}
          {responseError ? (
            <Alert message={responseError} type="error" />
          ) : null}
        </div>
      )}
    </S.Wrapper>
  )
}

export default SettingsMarginsCard

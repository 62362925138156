import { ExchangeDollar } from "@styled-icons/remix-fill/ExchangeDollar"
import styled from "styled-components"
import { Handshake } from "styled-icons/fa-regular"
import { NightsStay } from "styled-icons/material-outlined"
import { ReactComponent as MagnifyingGlassDollar } from "../../icons/magnifying-glass-dollar.svg"
import { colors, palette } from "../../styles"
import * as T from "../typography"

export const Wrapper = styled.div<{ isLoading: boolean }>`
  background-color: ${colors.iUltraDarkGrey};
  border-radius: 4px;
  min-width: 500px;
  max-width: 650px;
  height: 100%;
  padding: 16px;
  overflow-x: auto;
  ${({ isLoading }) =>
    isLoading
      ? `display: flex;
      align-items: center;
      justify-content: center;`
      : ``}
  @media (max-width: 599px) {
    min-width: 200px;
  }
`

export const SpinnerWrapper = styled.div`
  .ant-spin .ant-spin-dot-holder {
    color: ${palette.primary};
  }
`

export const TitleMonitoringWrapper = styled.div`
  text-align: center;
  width: 100%;
  padding-bottom: 8px;
  margin-bottom: 9px;
  color: ${colors.iWhite};
  border-bottom: 1px solid ${colors.iMediumGrey};
`

export const Title = styled(T.H3)``

export const HeaderLabel = styled(T.H4)`
  color: ${colors.iWhite};
  text-align: center;
  margin: 0 0.2em 0.5em;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const Table = styled.div`
  overflow-y: auto;
  display: grid;
  grid-template-columns: 2fr 3fr 3fr 3fr 3fr 3fr;
  grid-row-gap: 0.1em;
  align-items: center;
  height: 100%;
  max-height: 470px;
`

export const Row = styled.div`
  display: contents;

  :not(.header):hover > * {
    background-color: ${colors.iDarkGrey};
    transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`

export const RowValueLabel = styled(T.P3)`
  color: ${colors.iWhite};
  text-align: center;
  padding: 0.5em;
`

export const NotesWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`

export const MagnifyingGlassDollarStyled = styled(MagnifyingGlassDollar)`
  width: 16px;
  height: 16px;
  * {
    color: ${colors.iOrange};
  }
`

export const HandshakeStyled = styled(Handshake)`
  color: ${colors.iOrange};
`

export const ExchangeIconStyled = styled(ExchangeDollar)`
  color: ${colors.iOrange};
`

export const SwapsIconStyled = styled(NightsStay)`
  color: ${colors.iOrange};
`

import styled from "styled-components"
import * as T from "../typography"
import { colors } from "../../styles"

export const GridWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr 1fr 1fr 1fr 1.1fr 0.9fr;
  grid-row-gap: 10px;
  grid-column-gap: 1px;
  padding: 10px 2px;
`

export const GridHeader = styled(T.Notes)`
  color: ${colors.iMediumGrey};
  padding-right: 1px;
  text-align: right;
  :first-child {
    text-align: left;
  }
  white-space: nowrap;
`

export const GridPairLabel = styled(T.P3)`
  font-family: Lato-Bold;
  white-space: nowrap;
`

export const GridLabel = styled(T.P3)`
  text-align: right;
  white-space: nowrap;
`

export const GridColored = styled(T.P3)<{
  isNegative: boolean
  isSizeZero?: boolean
}>`
  color: ${props =>
    props.isSizeZero
      ? colors.iWhite
      : props.isNegative
        ? colors.iRed
        : colors.iGreen};
  text-align: right;
`

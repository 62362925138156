import { Spin } from "antd"
import axios from "axios"
import _ from "lodash"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getPairFromId, sortPairById } from "../../helpers"
import { CompareStateResponse } from "../../types"
import { AuthContext } from "../auth-context"
import { percentageToColor } from "./helpers"
import * as S from "./styled"

const url =
  (process.env.REACT_APP_ENDPOINT || "http://localhost:4000") +
  "/webFx/compare-state"

const MonitoringWebFxComparisson: React.FC<{
  compare: "balance" | "numberOfDeals"
}> = ({ compare }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [data, setData] = useState<CompareStateResponse>([])
  const { getCurrentUser } = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true)
    getCurrentUser()
      .then(user => {
        if (!user.isLogged) {
          navigate("/login")
          return
        }
        return axios
          .get<CompareStateResponse>(url, {
            headers: {
              Authorization: user.tokens.token,
            },
          })
          .then(({ data }) => {
            if (!data.length) return
            const pairwiseData = Object.values(_.groupBy(data, d => d.importId))
              .sort((a, b) => b[0].timestamp - a[0].timestamp)[0]
              .filter(x => x.type === compare)
              .sort((a, b) => sortPairById(a.pair, b.pair))
            const aggregatedPair: CompareStateResponse[number] = {
              id: 123,
              timestamp: Date.now(),
              type: compare,
              importId: 123,
              webFx: _.sum(pairwiseData.map(d => d.webFx)),
              fspotter: _.sum(pairwiseData.map(d => d.fspotter)),
              pair: "all",
              broker: "aggregated",
            }
            setData([...pairwiseData, aggregatedPair])
          })
      })
      .then(() => setIsLoading(false))
      .catch(e => {
        console.error(e)
      })
  }, [compare, getCurrentUser, navigate])

  if (isLoading)
    return (
      <S.CardWrapper isLoading>
        <S.SpinnerWrapper>
          <Spin />
        </S.SpinnerWrapper>
      </S.CardWrapper>
    )

  const title =
    compare === "balance" ? "WebFX  (Balance USD)" : "WebFX  (# Deals)"
  const lastUpdate = new Intl.DateTimeFormat("en-GB", {
    day: "numeric",
    month: "numeric",

    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  }).format(data[0]?.timestamp ?? 0)

  return (
    <S.CardWrapper>
      <S.CardTitleWrapper>
        <S.CardTitle>{title}</S.CardTitle>
      </S.CardTitleWrapper>
      <S.WebFxContentWrapper>
        <S.Row className="header">
          {["Pair", "WebFX", "FSpotter", "% Difference"].map(label => (
            <S.HeaderLabel
              key={`${compare}-${label}-header`}
              right={["WebFX", "FSpotter", "% Difference"].includes(label)}
            >
              {label}
            </S.HeaderLabel>
          ))}
        </S.Row>
        {Object.values(data).flatMap((d, idx) => {
          const percentage = ((d.fspotter - d.webFx) / d.fspotter) * 100

          const significantDigits = compare === "balance" ? 2 : 0
          const format = Intl.NumberFormat("en", {
            maximumFractionDigits: significantDigits,
            minimumFractionDigits: significantDigits,
          }).format

          return [
            <S.Row key={`${compare}-${idx}-row}`}>
              <S.Text key={`${compare}-${idx}-a`} center>
                {getPairFromId(d.pair).label}
              </S.Text>
              <S.Text key={`${compare}-${idx}-b`} right>
                {format(d.webFx)}
              </S.Text>
              <S.Text key={`${compare}-${idx}-c`} right>
                {format(d.fspotter)}
              </S.Text>
              <S.Text
                key={`${compare}-${idx}-d`}
                color={percentageToColor(percentage)}
                right
              >
                {percentage.toFixed(2)}
              </S.Text>
            </S.Row>,
          ]
        })}
      </S.WebFxContentWrapper>
      <S.BottomText>Last Update: {lastUpdate}</S.BottomText>
    </S.CardWrapper>
  )
}

export default MonitoringWebFxComparisson

import styled from "styled-components"
import { ButtonStyled as ButtonLogin } from "../../pages/login/styled"
import { breakpointNum, colors, palette } from "../../styles"
import * as T from "../typography"
import { Form } from "antd"

export const Wrapper = styled.div<{ isLoading: boolean }>`
  background-color: ${colors.iUltraDarkGrey};
  border-radius: 4px;
  width: min-content;
  min-width: 150px;
  height: 100%;
  min-height: 200px;
  padding: 15px;
  ${({ isLoading }) =>
    isLoading
      ? `display: flex;
      align-items: center;
      justify-content: center;`
      : ``}
`

export const TitleWrapper = styled.div`
  text-align: center;
  width: 100%;
  padding-bottom: 8px;
  margin-bottom: 16px;
  color: ${colors.iWhite};
  border-bottom: 1px solid ${colors.iMediumGrey};
`

export const Title = styled(T.H3)``

export const ButtonStyled = styled(ButtonLogin)`
  float: right;
  width: 70px;
  margin-right: 3px;
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 4fr 2fr;
  grid-column-gap: 1.0em;
  align-items: center;
  .ant-switch.ant-switch-checked {
    background: ${palette.primary};
  }
  .ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background: ${palette.primary};
  }
`

export const GridRow = styled.div`
  display: contents;
  padding: 0.2em;
  > p {
    margin-top: 0.3em;
  }
  @media (max-width: ${breakpointNum.tablet}px) {
    > p {
      margin-top: 0.9em;
    }
  }
`

export const GridCell = styled.div`
  display: block;
  margin: 0.2em;
  color: ${colors.iWhite};
  min-width: 95px;
  .ant-form-item {
    margin: 0 0 10px;
    justify-self: right;
  }
`

export const FormScrollable = styled.div`
  overflow-x: auto;
`

export const FormStyled = styled(Form)`
  #marginForm {
    margin-top: 10px;
    clear: right;
    padding-top: 25px;
  }
`

export const FormItemStyled = styled(Form.Item) <{ $red?: boolean }>`
  .ant-form-item-label {
    text-align: right;
  }

  @media (max-width: 575px) {
    .ant-form .ant-form-item .ant-form-item-control,
    .ant-form .ant-form-item .ant-form-item-label {
      flex: 0 0 100%;
      max-width: 50% !important;
    }
  }

  .ant-input-number-status-error {
    background: ${colors.iBlack} !important;
  }
`


export const SpinnerWrapper = styled.div`
  .ant-spin .ant-spin-dot-holder {
    color: ${palette.primary};
  }
`

import DelaysCard from "../../components/monitoring-card-delays"
import SocketsStatusCard from "../../components/monitoring-card-sockets"
import TradedPairsCard from "../../components/monitoring-card-traded-pairs"
import UsedBrokersCard from "../../components/monitoring-card-used-brokers"
import WebFxComparisonCard from "../../components/monitoring-card-webFx-comparison"
import * as S from "./styled"

export const Monitoring = () => {
  if (process.env.REACT_APP_INSTANCE_TYPE === "crypto")
    return (
      <S.Wrapper>
        <UsedBrokersCard />
        <TradedPairsCard />
        <DelaysCard />
        <SocketsStatusCard />
      </S.Wrapper>
    )

  return (
    <S.Wrapper>
      <UsedBrokersCard />
      <TradedPairsCard />
      <WebFxComparisonCard compare="numberOfDeals" />
      <WebFxComparisonCard compare="balance" />
      <DelaysCard />
    </S.Wrapper>
  )
}

import { Alert, Form, InputNumber, Spin } from "antd"
import axios from "axios"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { getBrokerPair, getPairStrip } from "../../helpers"
import { colors } from "../../styles"
import { BrokerPair, SlippageDataType } from "../../types"
import { AuthContext } from "../auth-context"
import CustomTooltip from "../tooltip"
import { reshapeAllData, reshapeDefaultValue } from "./helpers"
import * as S from "./styled"

const url =
  (process.env.REACT_APP_ENDPOINT || "http://localhost:4000") +
  "/settings/slippage"

const SettingsSlippageCard = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { getCurrentUser } = useContext(AuthContext)
  const [allData, setAllData] = useState<SlippageDataType>()
  const [form] = Form.useForm()
  const formRef = useRef(null)
  const [defaultValues, setDefaultValues] = useState<{}>({})
  const [responseError, setResponseError] = useState<boolean>()
  const [responseSuccess, setResponseSuccess] = useState<boolean>()

  const getSlippageData = useCallback(
    () =>
      getCurrentUser()
        .then(user => {
          if (!user.isLogged) return
          axios
            .get<SlippageDataType>(url, {
              headers: {
                Authorization: user.tokens.token,
              },
            })
            .then(({ data }) => {
              setAllData(data)
              const newDefaultValues = reshapeDefaultValue(data.slippageData)
              setDefaultValues(newDefaultValues)
              setIsLoading(false)
            })
        })
        .catch(console.error),
    [getCurrentUser],
  )

  useEffect(() => {
    setIsLoading(true)
    getSlippageData()
  }, [getSlippageData])

  const onFinish = async (values: any) => {
    setResponseSuccess(false)
    setResponseError(false)
    const dataToSend = {
      slippageData: values as Record<BrokerPair, number>,
    }
    const user = await getCurrentUser()
    if (!user.isLogged) return

    await axios
      .post<{
        lastUpdate: number
        data: Partial<Record<BrokerPair, number>>
      }>(url, dataToSend, {
        headers: {
          Authorization: user.tokens.token,
        },
      })
      .then(res => {
        if (allData && allData.slippageData) {
          const newAllData = reshapeAllData(allData, res.data)
          setAllData(newAllData)
          const newDefaultValues = reshapeDefaultValue(newAllData.slippageData)
          setDefaultValues(newDefaultValues)
          setResponseSuccess(true)
        } else throw new Error("Something went wrong")
      })
      .catch(err => {
        console.error(err)
        setResponseError(true)
      })
  }

  return (
    <S.Wrapper isLoading={isLoading}>
      {isLoading ? (
        <S.SpinnerWrapper>
          <Spin />
        </S.SpinnerWrapper>
      ) : (
        <div className="immovable">
          <S.TitleWrapper>
            <S.Title>Weekly Slippage Configuration (in PIPs)</S.Title>
          </S.TitleWrapper>
          <S.FormScrollable>
            <S.FormStyled
              name="slippageForm"
              layout={"horizontal"}
              onFinish={onFinish}
              form={form}
              ref={formRef}
              requiredMark={false}
              initialValues={defaultValues}
            >
              <S.Grid numCols={allData?.pairs.length ?? 0}>
                <S.GridRow>
                  <S.GridCell key={`undefined-pair`} />
                  {allData &&
                    allData.pairs.map((pair, index) => (
                      <S.GridHeaderCell key={`${index}-${pair}-pair`}>
                        {getPairStrip(pair)}
                      </S.GridHeaderCell>
                    ))}
                </S.GridRow>
                {allData &&
                  allData.brokers.map((broker, index) => (
                    <S.GridRow key={`brokerRow-${broker}-${index}`}>
                      <S.GridCell key={`${index}-${broker}-pair`}>
                        {broker}
                      </S.GridCell>
                      {allData.pairs.map(pair => {
                        const key = getBrokerPair(broker, pair) as BrokerPair
                        const valuesAreDifferent =
                          Math.abs(
                            allData.slippageData[key].aggregated -
                              allData.slippageData[key].expected,
                          ) > 1e-5
                        const tooMuchDelay =
                          new Date().getTime() -
                            new Date(allData.lastUpdate).getTime() >
                          1_000 * 60 * 12
                        return (
                          <CustomTooltip
                            key={`${key}-tooltip`}
                            show={true}
                            title={`Aggregator value is ${new Intl.NumberFormat(
                              "en-US",
                              {
                                style: "decimal",
                                maximumFractionDigits: 3,
                                minimumFractionDigits: 3,
                              },
                            ).format(
                              Number(allData.slippageData[key].aggregated),
                            )}`}
                            color={colors.iUltraDarkGrey}
                            overlayInnerStyle={{
                              borderColor: colors.iLightGrey,
                              borderWidth: 3,
                              borderStyle: "solid",
                              borderRadius: 10,
                            }}
                          >
                            <S.GridCell key={`${broker}-${pair}-item`}>
                              <S.FormItemStyled
                                $red={valuesAreDifferent && tooMuchDelay}
                                label={null}
                                name={key}
                                colon={false}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input a correct slippage!",
                                  },
                                ]}
                              >
                                <InputNumber
                                  controls={false}
                                  formatter={value => {
                                    if (value === undefined) return "0"
                                    return new Intl.NumberFormat("en-US", {
                                      style: "decimal",
                                      maximumFractionDigits: 3,
                                      minimumFractionDigits: 3,
                                    }).format(Number(value))
                                  }}
                                />
                              </S.FormItemStyled>
                            </S.GridCell>
                          </CustomTooltip>
                        )
                      })}
                    </S.GridRow>
                  ))}
              </S.Grid>
              <S.FormItemStyled {...{ wrapperCol: { span: 24, offset: 0 } }}>
                <S.ButtonStyled type="primary" htmlType="submit">
                  Submit
                </S.ButtonStyled>
              </S.FormItemStyled>
            </S.FormStyled>
          </S.FormScrollable>
          {responseSuccess ? (
            <Alert message="Data successfully updated" type="success" />
          ) : null}
          {responseError ? (
            <Alert message="Unfortunately an error occurred" type="error" />
          ) : null}
        </div>
      )}
    </S.Wrapper>
  )
}

export default SettingsSlippageCard

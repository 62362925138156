import styled from "styled-components"
import { CloseOutline } from "styled-icons/evaicons-outline"
import { colors } from "../../styles"
import * as T from "./../typography"
import { ReactComponent as Bin } from "../../icons/bin.svg"

export const ModalWrapper = styled.div`
  position: absolute;
  width: 460px;
  height: fit-content;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: ${colors.iWhite};
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.1);
  padding: 0 15px 0 15px;
  flex-grow: 1;
  z-index: 13;
`

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 13px 0;
  justify-content: space-between;
`

export const ModalHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 17px;
`

export const ModalHeaderTitle = styled(T.P1)`
  color: ${colors.iBlack};
  padding-bottom: 3px;
`

export const CloseStyled = styled(CloseOutline)`
  width: 25px;
  height: 25px;
  fill: ${colors.iMediumGrey};
  cursor: pointer;
`

export const ModalRow = styled.div`
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const ModalLabel = styled(T.P2)`
  color: ${colors.iBlack};
  padding-left: 13px;
`

export const StyledBin = styled(Bin)`
  margin-left: 14px;
  cursor: pointer;
  color: ${colors.iMediumGrey};
`

export const HorizontalSeparator = styled.div<{ isFirst?: boolean }>`
  background-color: ${colors.iUltraLightGrey};
  height: 1px;
  margin-bottom: ${props => (props.isFirst ? "9px" : "0")};
`

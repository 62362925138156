import { Button, Form, Select } from "antd"
import styled from "styled-components"
import { breakpointNum, colors, widgetLayouts, palette } from "../../styles"
import * as T from "../typography"

export const WrapperCard = styled.div<{ isLoading: boolean }>`
  background-color: ${colors.iUltraDarkGrey};
  border-radius: 4px;
  width: min-content;
  min-width: 400px;
  height: 100%;
  min-height: 230px;
  padding: 15px;
  ${({ isLoading }) =>
    isLoading
      ? `display: flex;
      align-items: center;
      justify-content: center;`
      : ``}
  @media (max-width: ${breakpointNum.tablet - 1}px) {
    min-width: 338px;
  }
`

export const SpinnerWrapper = styled.div`
  .ant-spin .ant-spin-dot-holder {
    color: ${palette.primary};
  }
`

export const TitleWrapper = styled.div`
  text-align: center;
  width: 100%;
  padding-bottom: 8px;
  margin-bottom: 9px;
  color: ${colors.iWhite};
  border-bottom: 1px solid ${colors.iMediumGrey};
`

export const TitleCard = styled(T.H3)``

export const TypeSelectContainerDiv = styled.div`
  padding-bottom: 25px;
  margin: 30px 0;
`

export const TypeLabel = styled.div`
  float: left;
  font-size: 16px;
  color: ${colors.iWhite};
`

export const FormHeader = styled.div`
  margin: 15px 0px 15px 98px;
  font-weight: bold;
  offset: 20px;
  color: #fff;
  text-align: center;
`

export const SelectStyled = styled(Select)`
  float: right;
  text-align: right;
  width: calc(100% - 97px);
  background-color: ${colors.iBlack};
  color: ${colors.iWhite};
  border-radius: 2px;
  @media (max-width: ${breakpointNum.tablet - 1}px) {
    padding: 4px;
  }
  span {
    color: ${({ disabled }) => (disabled ? colors.iMediumGrey : colors.iWhite)};
    word-wrap: break-word;
    @media (max-width: ${breakpointNum.tablet - 1}px) {
      line-height: 18px !important;
    }
  }

  & > span {
    display: ${({ disabled }) => (disabled ? "none" : "")};
  }
  .ant-select-arrow {
    @media (max-width: ${breakpointNum.tablet - 1}px) {
      margin-top: -3px;
    }
  }

  .ant-select-selection-item {
    padding-left: 16px;
    padding-right: 12px !important;
    font-size: 12px;

    word-wrap: break-word;
    @media (max-width: ${breakpointNum.tablet - 1}px) {
      font-size: 12px;
    }
  }

  .ant-select-selector {
    min-width: 70px;
    padding: 0 8px !important;
    @media (max-width: ${breakpointNum.tablet - 1}px) {
      height: ${widgetLayouts.subHeaderSmallHeightPx - 10}px !important;
    }
    ::after {
      width: 8px;
    }
  }
`
SelectStyled.defaultProps = {
  variant: "borderless",
  popupMatchSelectWidth: false,
}

export const FormStyled = styled(Form)`
  #marginForm {
    margin-top: 10px;
    clear: right;
    padding-top: 25px;
  }
`
export const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 3fr;
  grid-row-gap: 0.3em;
`

export const GridRow = styled.div`
  display: contents;
  padding: 0.2em;
`

export const GridCell = styled.div`
  display: block;
  line-height: 2.2em;
  color: ${colors.iWhite};
  min-width: 95px;
  white-space: nowrap;
  input {
    text-align: right;
  }
  .ant-form-item {
    margin: 0 0 10px;
  }
`

export const FormItemStyled = styled(Form.Item)`
  .ant-form-item-label {
    text-align: left;
  }
  .ant-form-item-label label {
    font-size: 16px;
    color: ${colors.iWhite};
  }
  .ant-input {
    text-align: right;
    background-color: ${colors.iBlack};
    color: ${colors.iWhite};
    border: 1px solid ${colors.iBlack};
  }
  .ant-input-number {
    width: 100%;
    text-align: right;
    background-color: ${colors.iBlack};
    color: ${colors.iWhite};
    border: 1px solid ${colors.iBlack};
    input {
      text-align: right;
    }
  }

  .ant-color-picker-trigger {
    background-color: ${colors.iBlack};
    width: 110px;
    justify-content: space-between;
  }

  .ant-color-picker-trigger-text {
    color: ${colors.iLightGrey};
  }

  .ant-color-picker-color-block {
  }

  @media (max-width: 575px) {
    .ant-form .ant-form-item .ant-form-item-control,
    .ant-form .ant-form-item .ant-form-item-label {
      flex: 0 0 100%;
      max-width: 50% !important;
    }
  }
`

export const GridCellRight = styled(GridCell)`
  text-align: right;
  font-weight: bold;
`

export const ButtonStyled = styled(Button)`
  ${T.globalParagraphStylesTypography}

  background-color: ${palette.primary};
  border-radius: 2px;
  border-color: transparent;
  float: right;
  width: 89px;
  margin-right: 3px;
  margin-bottom: 10px;

  :hover {
    background-color: ${palette.hover};
    border-color: transparent;
  }
`

import useResizeObserver from "@react-hook/resize-observer"
import axios from "axios"
import HighchartsReact from "highcharts-react-official"
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { z } from "zod"
import { computationalExpensiveGraphs } from "../../constants"
import {
  getBrokerPair,
  getGraphData,
  getPairFromId,
  getWeekStart,
  mergeNewData,
  zip,
} from "../../helpers"
import indexDb from "../../helpers/indexDB"
import { colors } from "../../styles"
import { GraphInfo, GraphReactComponent, PepTick } from "../../types"
import { AuthContext } from "../auth-context"
import MemoizedPepGraph from "../pep-memoized-graph"
import WidgetHeader from "../widget-header"
import { WidgetSubheader } from "../widget-subheader"
import { WidgetWrapper } from "../widget-wrapper"
import {
  computeGraphExtremes,
  formatVolume,
  onMouseLeave,
  onMouseMove,
} from "./helpers"
import * as S from "./styled"

const PEPIndexedDBValidator = z.object({
  data: z.tuple([
    z.array(z.tuple([z.number(), z.number()])),
    z.array(z.tuple([z.number(), z.number()])),
    z.array(z.tuple([z.number(), z.number()])),
  ]),
  savedAt: z.number(),
})

const PepGraph: GraphReactComponent = ({
  graphs,
  setGraphs,
  socket,
  broker,
  pair,
  id,
  rangeMin,
  rangeMax,
}) => {
  const priceChart = useRef<HighchartsReact.RefObject>(null)
  const equityChart = useRef<HighchartsReact.RefObject>(null)
  const sizeChart = useRef<HighchartsReact.RefObject>(null)

  const brokerPair = getBrokerPair(broker, pair)
  const precision = useMemo(() => getPairFromId(pair).bpPrecision, [pair])
  const [loadedHistorical, setLoadedHistorical] = useState(false)
  const [graphsExtremes, setGraphsExtremes] = useState<{
    min: number
    max: number
  }>({
    min: rangeMin ? rangeMin : Date.now() - 1000 * 60 * 30,
    max: rangeMax ? rangeMax : Date.now(),
  })

  const wrapperRef = useRef<HTMLDivElement>(null)
  useResizeObserver(wrapperRef, () => {
    window.dispatchEvent(new Event("resize"))
  })

  useLayoutEffect(() => {
    const graphs = [priceChart, equityChart, sizeChart]
    graphs.forEach(chartComponentRef => {
      chartComponentRef?.current?.chart?.xAxis[0].setExtremes(
        graphsExtremes.min,
        graphsExtremes.max,
        undefined,
        false,
        { trigger: "syncExtremes" },
      )
    })
  }, [graphsExtremes.min, graphsExtremes.max])

  const setExtremes =
    useCallback<Highcharts.AxisSetExtremesEventCallbackFunction>(e => {
      if (e.trigger !== "syncExtremes") {
        setGraphsExtremes({ min: e.min, max: e.max })
        e.preventDefault()
      }
    }, [])

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"))
    }, 0)
  }, [])

  const numberOfExpensiveGraphs = useMemo(
    () =>
      graphs.filter(({ type }) => computationalExpensiveGraphs.includes(type))
        .length,
    [graphs],
  )

  useEffect(() => {
    if (!priceChart.current || !equityChart.current || !sizeChart.current)
      return
    const graphs = [priceChart, equityChart, sizeChart]

    const throttleTimeInMilliSeconds =
      5_000 * Math.ceil(numberOfExpensiveGraphs / 5)
    const redrawInterval = setInterval(
      () =>
        graphs.forEach(chartComponentRef =>
          chartComponentRef.current?.chart.redraw(false),
        ),
      throttleTimeInMilliSeconds,
    )
    return () => {
      clearInterval(redrawInterval)
    }
  }, [priceChart, equityChart, sizeChart, numberOfExpensiveGraphs])

  const { getCurrentUser } = useContext(AuthContext)
  useEffect(() => {
    if (loadedHistorical) return
    const asyncTrick = async () => {
      try {
        const url =
          (process.env.REACT_APP_ENDPOINT || "http://localhost:4000") +
          "/liquidity-dashboard/PEP"

        const user = await getCurrentUser()
        if (!user.isLogged) return

        const indexDbKey = `pep-${brokerPair}`

        const loadedSafeData = PEPIndexedDBValidator.safeParse(
          await indexDb.get(indexDbKey),
        )
        let cachedData: z.infer<typeof PEPIndexedDBValidator> = {
          data: [[], [], []],
          savedAt: 0,
        }
        if (loadedSafeData.success) {
          cachedData = loadedSafeData.data
        }

        if (cachedData.savedAt < getWeekStart().getTime()) {
          cachedData = { data: [[], [], []], savedAt: 0 }
          indexDb.remove(indexDbKey)
        }

        const { data: res } = await axios.get<{
          data: [[number, number][], [number, number][], [number, number][]]
          savedAt: number
        }>(url, {
          params: {
            pair,
            broker: broker.id,
            from: cachedData.savedAt,
          },
          headers: {
            Authorization: user.tokens.token,
          },
        })

        const historicalData = zip(cachedData.data, res.data).map(
          ([cachedSeries, resSeries]) => [...cachedSeries, ...resSeries],
        )
        indexDb.put(indexDbKey, {
          savedAt: res.savedAt,
          data: historicalData,
        })

        const allGraphs = [priceChart, sizeChart, equityChart]

        zip(allGraphs, historicalData).forEach(
          ([chartComponentRef, points]) => {
            const graphData = getGraphData(chartComponentRef) || []

            chartComponentRef.current?.chart.series[0].setData(
              mergeNewData(points, graphData[0]),
              false,
              false,
              false,
            )
          },
        )

        const { min: newMin, max: newMax } = computeGraphExtremes(equityChart)
        allGraphs.forEach(chartComponentRef => {
          chartComponentRef.current?.chart.xAxis[0].setExtremes(
            newMin,
            newMax,
            false,
            false,
          )
        })
        setLoadedHistorical(true)
      } catch (err) {
        console.error(err)
      }
    }
    asyncTrick()
  }, [broker, pair, getCurrentUser, brokerPair, loadedHistorical])

  const pepListener = useCallback(
    (tick: PepTick) => {
      if (tick.brokerPair !== brokerPair) return

      const chartDataPairs = [
        [priceChart, tick.data.price],
        [sizeChart, tick.data.sizeUSD],
        [equityChart, tick.data.equityUSD],
      ] as const

      chartDataPairs.forEach(([chart, data]) => {
        const series = chart?.current?.chart?.series[0]
        series?.addPoint([tick.data.timestamp, data], false, false)
      })
    },
    [brokerPair],
  )

  useEffect(() => {
    if (!socket) return () => {}
    socket.on("pep", pepListener)

    return () => socket.off("pep", pepListener)
  }, [socket, pepListener])

  const graphInfo: GraphInfo = useMemo(
    () => ({
      broker,
      pair,
      type: "pep",
      id,
      rangeMin: graphsExtremes.min,
      rangeMax: graphsExtremes.max,
      Graph: PepGraph,
    }),
    [broker, id, pair, graphsExtremes.min, graphsExtremes.max],
  )
  const pairLabel = getPairFromId(pair).label

  return (
    <WidgetWrapper>
      <WidgetHeader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
        widgetTitle="PEP Graph"
      />
      <WidgetSubheader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
      />
      <S.PepWrapper
        className="immovable"
        ref={wrapperRef}
        onMouseMove={onMouseMove([priceChart, equityChart, sizeChart])}
        onTouchMove={onMouseMove([priceChart, equityChart, sizeChart])}
        onMouseLeave={onMouseLeave([priceChart, equityChart, sizeChart])}
        onTouchEnd={onMouseLeave([priceChart, equityChart, sizeChart])}
        onMouseDown={e => e.stopPropagation()}
      >
        <S.PriceGraphWrapper>
          <MemoizedPepGraph
            isFirst={true}
            chartComponentRef={priceChart}
            precision={precision}
            loadedHistorical={loadedHistorical}
            setExtremes={setExtremes}
            color={colors.iPrimaryBlue}
            title="PRICE"
            toolTipFormatter={(y: number) =>
              y.toLocaleString("en", {
                style: "currency",
                currency: pairLabel.slice(3),
                maximumFractionDigits: precision,
                minimumFractionDigits: precision,
                currencyDisplay: "narrowSymbol",
              })
            }
          />
        </S.PriceGraphWrapper>
        <S.ExposureGraphWrapper>
          <MemoizedPepGraph
            chartComponentRef={sizeChart}
            precision={precision}
            loadedHistorical={loadedHistorical}
            setExtremes={setExtremes}
            color={colors.iGreen}
            negativeColor={colors.iRed}
            title="EXPOSURE (USD)"
            toolTipFormatter={formatVolume}
          />
        </S.ExposureGraphWrapper>
        <S.PNLGraphWrapper>
          <MemoizedPepGraph
            isLast={true}
            chartComponentRef={equityChart}
            precision={precision}
            loadedHistorical={loadedHistorical}
            setExtremes={setExtremes}
            color={colors.iPurple}
            title="PNL (USD)"
            toolTipFormatter={(y: number) =>
              y.toLocaleString("en", {
                style: "currency",
                currency: "USD",
                currencyDisplay: "narrowSymbol",
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })
            }
            labelFormatter={(y: number) =>
              y.toLocaleString("en", {
                style: "currency",
                currency: "USD",
                currencyDisplay: "narrowSymbol",
                maximumFractionDigits: 0,
              })
            }
          />
        </S.PNLGraphWrapper>
      </S.PepWrapper>
    </WidgetWrapper>
  )
}

export default PepGraph

import styled from "styled-components"

export const Wrapper = styled.div`
  flex-grow: 1;
  flex-basis: auto;
  overflow: hidden;
`

export const LegendWrapper = styled.div`
  width: 140px;
  position: absolute;
  top: 85px;
  right: 35px;
  z-index: 1;
`

export const Dot = styled.span<{ color: string }>`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  float: left;
  background-color: ${({ color }) => color};
  margin-right: 3px;
  margin-top: 3px;
`

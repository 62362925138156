import styled from "styled-components"
import { breakpointNum, colors, palette, PlusStyled } from "../../styles"
import * as T from "../typography"
import { Button, Form, Select } from "antd"
import { ButtonStyled as LoginButton } from "../../pages/login/styled"

export const Wrapper = styled.div<{ isLoading: boolean }>`
  background-color: ${colors.iUltraDarkGrey};
  border-radius: 4px;
  width: min-content;
  min-width: 400px;
  height: 100%;
  min-height: 230px;
  padding: 15px;
  ${({ isLoading }) =>
    isLoading
      ? `display: flex;
      align-items: center;
      justify-content: center;`
      : `display: flex;
      flex-direction: column;`}
  @media (max-width: ${breakpointNum.tablet - 1}px) {
    min-width: 338px;
  }
`

export const SpinnerWrapper = styled.div`
  .ant-spin .ant-spin-dot-holder {
    color: ${palette.primary};
  }
`

export const TitleWrapper = styled.div`
  text-align: center;
  width: 100%;
  padding-bottom: 8px;
  margin-bottom: 9px;
  color: ${colors.iWhite};
  border-bottom: 1px solid ${colors.iMediumGrey};
`

export const Title = styled(T.H3)``

export const FormStyled = styled(Form)`
  #marginForm {
    margin-top: 10px;
    clear: right;
    padding-top: 25px;
  }

  .ant-btn-sm.ant-btn-primary {
    background: ${palette.primary};
  }
`

export const ButtonStyled = styled(LoginButton)`
  float: right;
  width: 89px;
  margin-right: 3px;
  margin-bottom: 10px;
  border-radius: 2px;
`
export const AddLineButton = styled(Button)`
  border-style: solid;
  opacity: 0.8;
  border-radius: 2px;
  background: ${colors.iBlack};
  color: ${colors.iWhite};
  border-color: ${colors.iTransparent};
  :active,
  :hover {
    opacity: 1;
    color: ${colors.iWhite}!important;
    border-color: ${colors.iTransparent}!important;
    background: ${colors.iBlack}!important;
  }
`
export const FormItemStyled = styled(Form.Item)`
  margin-bottom: 12px;

  .ant-form-item-label {
    text-align: left;
  }

  .ant-form-item-label label {
    font-size: 16px;
    color: ${colors.iWhite};
  }
  .ant-select-selection-placeholder {
    color: ${colors.iWhite};
  }
  .ant-input,
  .ant-select,
  .ant-input-number-input {
    text-align: right;
    min-width: 100px;
    border-radius: 2px !important;
    background-color: ${colors.iBlack};
    color: ${colors.iWhite};
    border: 1px solid ${colors.iBlack};
  }
  .ant-input-number-input::placeholder,
  .ant-select::placeholder {
    color: ${colors.iWhite};
  }

  .ant-input-number-input::-ms-input-placeholder,
  .ant-select::-ms-input-placeholder {
    color: ${colors.iWhite};
  }

  .ant-input-number-input:active::placeholder,
  .ant-select:active::placeholder,
  .ant-input-number-input:hover::placeholder,
  .ant-select:hover::placeholder {
    color: ${colors.iTransparent};
  }

  .ant-input-number-input:active::-ms-input-placeholder,
  .ant-select:active::-ms-input-placeholder {
    color: ${colors.iTransparent};
  }

  .ant-select-selector {
    color: ${colors.iWhite};
    background: ${colors.iBlack}!important;
  }
  .ant-input-number {
    width: 100%;
    text-align: right;
    border-radius: 2px;
    background-color: ${colors.iBlack};
    color: ${colors.iWhite};
    border: 1px solid ${colors.iBlack};
    input {
      text-align: right;
    }
  }
  .ant-form {
    color: ${colors.iWhite};
  }
`
export const SelectStyled = styled(Select)`
  max-width: 110px;
  background-color: ${colors.iBlack};
  color: ${colors.iLightGrey};
  span {
    color: ${({ disabled }) => (disabled ? colors.iMediumGrey : colors.iWhite)};
    word-wrap: break-word;
    @media (max-width: ${breakpointNum.tablet - 1}px) {
      line-height: 18px !important;
    }
  }

  & > span {
    display: ${({ disabled }) => (disabled ? "none" : "")};
  }
  .ant-select-arrow {
    right: 2px;
    @media (max-width: ${breakpointNum.tablet - 1}px) {
      margin-top: -3px;
    }
  }
  .ant-select-selection-item {
    padding-left: 16px;
    padding-right: 12px !important;
    font-size: 11px;
    word-wrap: break-word;
    @media (max-width: ${breakpointNum.tablet - 1}px) {
      font-size: 10px;
    }
  }

  .ant-select-selector {
    min-width: 70px;
    padding: 0 8px !important;
  }
`

SelectStyled.defaultProps = {
  variant: "borderless",
  popupMatchSelectWidth: false,
}

export const AddPairWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 180px;
  gap: 12px;
  padding: 8px 4px 4px 4px;
  border-top: 1px solid ${colors.iUltraLightGrey};
  .ant-input-outlined:hover {
    border-color: ${colors.iMediumGrey};
  }
  .ant-input-outlined:focus {
    border-color: ${colors.iDarkGrey};
  }
`

export const AddPairErrorWrapper = styled.div`
  padding-left: 0.5em;
  padding-right: 0.5em;
`

export const AddPairErrorMessage = styled(T.P2)`
  color: ${colors.iRedText};
`

export const PlusIconButton = styled(PlusStyled) <{ disabled: boolean }>`
  :hover {
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    color: ${({ disabled }) => (disabled ? colors.iRed : palette.primary)};
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`

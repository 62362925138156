import { useCallback, useEffect, useMemo, useState } from "react"
import {
  GraphInfo,
  GraphReactComponent,
  WftCostsData,
  WftCostsTick,
} from "../../types"
import WidgetHeader from "../widget-header"
import { WidgetSubheader } from "../widget-subheader"
import { WidgetWrapper } from "../widget-wrapper"
import { formatCostsWft } from "./helpers"
import * as S from "./styled"

const CostsWidget: GraphReactComponent = ({
  socket,
  broker,
  pair,
  graphs,
  setGraphs,
  id,
  vwap,
  comm,
}) => {
  const [totalCost, setTotalCost] = useState(0)
  const [commission, setCommission] = useState(0)
  const [slippage, setSlippage] = useState(0)
  const [splitting, setSplitting] = useState(0)
  const [swap, setSwap] = useState(0)
  const [quotedSpread, setQuotedSpread] = useState(0)
  //const [totalSpread, setTotalSpread] = useState(0)
  const [dataAvailable, setDataAvailable] = useState(false)

  const wftCostsListener = useCallback((tick: WftCostsTick) => {
    if (tick.brokerPair === "aggregated-all") {
      const typedTick = tick.data as WftCostsData
      setDataAvailable(true)
      setTotalCost(typedTick.totalCost)
      setCommission(typedTick.commission)
      setSlippage(typedTick.slippage)
      setSplitting(typedTick.splitting)
      setSwap(typedTick.swap)
      setQuotedSpread(typedTick.quotedSpread)
      //setTotalSpread(typedTick.totalSpread)
    }
  }, [])

  const graphInfo: GraphInfo = useMemo(
    () => ({
      broker,
      pair,
      type: "costs",
      id,
      Graph: CostsWidget,
      vwap,
      comm,
    }),
    [broker, comm, id, pair, vwap],
  )
  useEffect(() => {
    if (!socket) return () => {}
    socket.on("costs", wftCostsListener)

    return () => socket.off("costs", wftCostsListener)
  }, [socket, wftCostsListener])

  return (
    <WidgetWrapper>
      <WidgetHeader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
        widgetTitle="Costs"
      />
      <WidgetSubheader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
      />
      <S.Wrapper>
        <S.Row>
          <S.RowElementBigger>Total cost</S.RowElementBigger>
          <S.RowElementBigger isNegative={totalCost < 0}>
            {dataAvailable ? formatCostsWft(totalCost) : "n/a"}
          </S.RowElementBigger>
        </S.Row>
        <S.Row>
          <S.RowElement>Commissions</S.RowElement>
          <S.RowElement isNegative={commission < 0}>
            {dataAvailable ? formatCostsWft(commission) : "n/a"}
          </S.RowElement>
        </S.Row>
        <S.Row>
          <S.RowElement>Quoted spread</S.RowElement>
          <S.RowElement isNegative={quotedSpread < 0}>
            {dataAvailable ? formatCostsWft(quotedSpread) : "n/a"}
          </S.RowElement>
        </S.Row>
        <S.Row>
          <S.RowElement>Slippage</S.RowElement>
          <S.RowElement isNegative={slippage < 0}>
            {dataAvailable ? formatCostsWft(slippage) : "n/a"}
          </S.RowElement>
        </S.Row>
        <S.Row>
          <S.RowElement>Splitting</S.RowElement>
          <S.RowElement isNegative={splitting < 0}>
            {dataAvailable ? formatCostsWft(splitting) : "n/a"}
          </S.RowElement>
        </S.Row>
        <S.Row>
          <S.RowElement>Swap</S.RowElement>
          <S.RowElement isNegative={swap < 0}>
            {dataAvailable ? formatCostsWft(swap) : "n/a"}
          </S.RowElement>
        </S.Row>
      </S.Wrapper>
    </WidgetWrapper>
  )
}

export default CostsWidget

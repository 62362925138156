import { pairList } from "../../constants"
import { sortPairById } from "../../helpers"
import { DelaysDataResponseType, PairId } from "../../types"

const reshapeResponse = (
  res: DelaysDataResponseType,
  rowsMapper: {
    broker: string
    val: number
  }[],
  colsMapper: {
    pair: string
    val: number
  }[],
) =>
  res
    .map(data => ({
      ...data,
      sortVal:
        (rowsMapper.find(({ broker }) => broker === data.broker)?.val ?? 0) +
        (colsMapper.find(({ pair }) => pair === data.pair)?.val ?? 0),
    }))
    .sort((a, b) => (a.sortVal < b.sortVal ? -1 : +1))
    .map(
      val =>
        [
          colsMapper.findIndex(({ pair }) => val.pair === pair),
          rowsMapper.findIndex(({ broker }) => val.broker === broker),
          val.delay,
        ] as [x: number, y: number, delay: number],
    )

export const reshapeDelaysData = (
  data: DelaysDataResponseType,
  uniquePairs: {
    pair: PairId
    val: number
  }[],
  sortedBrokers: {
    broker: string
    val: number
  }[],
) => ({
  columns: uniquePairs
    .map(({ pair }) => pair)
    .sort(sortPairById)
    .map(p => pairList.find(({ id }) => id === p)?.label || ""),
  rows: sortedBrokers.map(({ broker }) => broker),
  data: reshapeResponse(data, sortedBrokers, uniquePairs),
})

export const getUniqueUsedBrokers = (data: DelaysDataResponseType) =>
  [
    ...new Set(
      data
        .filter(({ consolidated }) => consolidated)
        .map(({ broker }) => broker),
    ),
  ].sort()

export const getUniqueNonUsedBrokers = (data: DelaysDataResponseType) =>
  [
    ...new Set(
      data
        .filter(({ consolidated }) => !consolidated)
        .map(({ broker }) => broker),
    ),
  ].sort()

export const getSortedBrokers = (
  uniqueUsedBrokers: string[],
  uniqueNonUsedBrokers: string[],
) =>
  [...uniqueUsedBrokers, ...uniqueNonUsedBrokers].map((broker, idx) => ({
    broker,
    val: idx * 1000,
  }))

export const getUniquepairs = (data: DelaysDataResponseType) =>
  [...new Set(data.map(({ pair }) => pair))]
    .sort(sortPairById)
    .map((pair, idx) => ({ pair, val: idx }))

import React, { useCallback, useMemo } from "react"
import { pairList } from "../../constants"
import { getPairFromId, sortPairById } from "../../helpers"
import {
  Broker,
  BrokerId,
  GraphOptions,
  Pair,
  PairId,
  PartialRecord,
} from "../../types"

type PairsBrokersListContextType = {
  activePairs: Pair[]
  tradedPairs: Pair[]
  allPairs: Pair[]
  brokersList: Broker[]
  colorsMapper: Record<string, string>
  isPairValid: (pair: PairId, graphOptions: GraphOptions) => boolean
}

export const PairsBrokersListContext =
  React.createContext<PairsBrokersListContextType>({
    activePairs: [],
    tradedPairs: [],
    allPairs: [],
    brokersList: [],
    colorsMapper: {},
    isPairValid: (_pair: PairId, _graphOptions: GraphOptions) => false,
  })

const brokerLabelExceptions: PartialRecord<BrokerId, string> = {
  aggregated: "CONSOLIDATED",
}

const PairsBrokers = ({
  pairsBrokersList,
  tradedPairsId,
  brokersList,
  colorsMapper,
  children,
}: {
  pairsBrokersList: [PairId, BrokerId][]
  tradedPairsId: PairId[]
  brokersList: Omit<Broker, "label">[]
  colorsMapper: Record<string, string>
  children: React.ReactNode
}) => {
  const filteredPairsBrokers: [Pair, Broker][] = useMemo(
    () =>
      pairsBrokersList
        .map(([pair, broker]) => [
          getPairFromId(pair),
          brokersList.find(b => b.id === broker),
        ])
        .filter(([_pair, broker]) => broker !== undefined) as [Pair, Broker][],
    [pairsBrokersList, brokersList],
  )

  // all the pairs we receive quotes of
  const activePairs: Pair[] = useMemo(
    () =>
      [...new Set(filteredPairsBrokers.map(([pair, _broker]) => pair))].sort(
        (a, b) => sortPairById(a.id, b.id),
      ),
    [filteredPairsBrokers],
  )

  // all the pairs we receive trades of, or have tradeSize > 0 in tradeConfig
  const tradedPairs: Pair[] = useMemo(
    () => pairList.filter(({ id }) => tradedPairsId.includes(id)),
    [tradedPairsId],
  )

  // union of the pairs we receive
  // - quotes
  // - deals
  // - have tradeSize > 0 in tradeConfig
  const allPairs: Pair[] = useMemo(
    () =>
      [...new Set([...activePairs, ...tradedPairs])].sort((a, b) =>
        sortPairById(a.id, b.id),
      ),
    [activePairs, tradedPairs],
  )

  const brokersListWithLabels = useMemo(
    () =>
      [
        ...brokersList,
        {
          id: "aggregated",
          isPOP: false,
          isUsed: true,
          isPartOfPOP: false as false,
        },
      ].map(b => ({
        label: brokerLabelExceptions[b.id] ?? b.id,
        ...b,
      })),
    [brokersList],
  )

  const isPairValid = useCallback(
    (pair: PairId, graphOptions: GraphOptions) => {
      const tradedPairsId = tradedPairs.map(({ id }) => id)
      if (pair === "all" && graphOptions.pairs.aggregated) return true
      if (pair !== "all" && graphOptions.pairs.allIndividual) return true
      if (tradedPairsId.includes(pair) && graphOptions.pairs.onlyUsedForTrading)
        return true
      return false
    },
    [tradedPairs],
  )

  return (
    <PairsBrokersListContext.Provider
      value={{
        activePairs,
        allPairs,
        tradedPairs,
        isPairValid,
        brokersList: brokersListWithLabels,
        colorsMapper,
      }}
    >
      {children}
    </PairsBrokersListContext.Provider>
  )
}
export default PairsBrokers

import { Spin } from "antd"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { POPIconStyled } from "../../styles"
import {
  CryptoStrategySummaryTick,
  CryptoSummaryStrategyData,
  GraphInfo,
  GraphReactComponent,
} from "../../types"
import WidgetHeader from "../widget-header"
import { WidgetSubheader } from "../widget-subheader"
import { WidgetWrapper } from "../widget-wrapper"
import { formatDeals, formatTable, formatVolumeLabel } from "./helpers"
import * as S from "./styled"

const columnLabels = ["_POP/BROKER", "DEALS", "VOLUME", "BALANCE"]

const getFilter =
  (modalityPop: boolean) =>
  (data: CryptoSummaryStrategyData["arbitrage"][number]) => {
    if (modalityPop)
      return data.brokerType === "Pop" || data.brokerType === "SingleBroker"
    else
      return (
        data.brokerType === "PartOfPop" || data.brokerType === "SingleBroker"
      )
  }

export const CryptoStrategySummary: GraphReactComponent = ({
  socket,
  broker,
  pair,
  graphs,
  setGraphs,
  id,
  vwap,
  comm,
}) => {
  const [modalityPOP, setModalityPOP] = useState(true)
  const [strategy, setStrategy] = useState<"ai-strategy" | "arbitrage">(
    "ai-strategy",
  )
  const [allData, setAllData] = useState<CryptoSummaryStrategyData>()

  const dataToShow = useMemo(
    () =>
      allData ? allData[strategy].filter(getFilter(modalityPOP)) : undefined,
    [strategy, allData, modalityPOP],
  )

  const cryptoStrategySummaryListener = useCallback(
    (tick: CryptoStrategySummaryTick) => {
      if (tick.brokerPair === "aggregated-all") setAllData(tick.data)
    },
    [],
  )

  const graphInfo: GraphInfo = useMemo(
    () => ({
      broker,
      pair,
      type: "cryptoStrategySummary",
      id,
      Graph: CryptoStrategySummary,
      vwap,
      comm,
    }),
    [broker, comm, id, pair, vwap],
  )

  useEffect(() => {
    if (!socket) return () => {}
    socket.on("cryptoStrategySummary", cryptoStrategySummaryListener)
    return () =>
      socket.off("cryptoStrategySummary", cryptoStrategySummaryListener)
  }, [socket, cryptoStrategySummaryListener])

  return (
    <WidgetWrapper>
      <WidgetHeader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
        widgetTitle="Crypto - Strategy Summary"
      />
      <WidgetSubheader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
        options={[
          {
            type: "toggle",
            value: modalityPOP,
            onClick: () => {
              setModalityPOP(!modalityPOP)
            },
            Icon: POPIconStyled,
            title: modalityPOP ? "switch to Brokers" : "switch to POP",
          },
          {
            type: "radio",
            optionsValues: [
              { label: "AI STRATEGY", value: "ai-strategy" },
              { label: "ARBITRAGE", value: "arbitrage" },
            ],
            onChange: e => setStrategy(e.target.value),
            value: strategy,
          },
        ]}
      />
      {!dataToShow ? (
        <S.SpinnerWrapper>
          <Spin />
        </S.SpinnerWrapper>
      ) : (
        <S.GridWrapper>
          {columnLabels.map((label, index) => (
            <S.GridHeader key={label}>
              {index === 0 ? (modalityPOP ? "POP" : "BROKER") : label}
            </S.GridHeader>
          ))}

          {/* NOT AGGREGATED */}
          {dataToShow
            .filter(x => x.brokerType !== "SingleBroker")
            .sort((a, b) => (a.numberOfDeals > b.numberOfDeals ? -1 : +1))
            .map((row, idx) => (
              <React.Fragment key={idx}>
                <S.CellLeft>{row.broker}</S.CellLeft>
                <S.CellCenter>{formatDeals(row.numberOfDeals)}</S.CellCenter>
                <S.CellRight>{formatVolumeLabel(row.volume)}</S.CellRight>
                <S.CellRight>
                  {row.balance ? formatTable(row.balance) : "n/a"}
                </S.CellRight>
              </React.Fragment>
            ))}

          {/* AGGREGATED */}
          {dataToShow
            .filter(x => x.brokerType === "SingleBroker")
            .map((aggregatedData, idx) => (
              <React.Fragment key={`aggregated-${idx}`}>
                <S.CellTotalLeft>CONSOLIDATED</S.CellTotalLeft>
                <S.CellTotalCenter>
                  {formatDeals(aggregatedData?.numberOfDeals ?? -1)}
                </S.CellTotalCenter>
                <S.CellTotalRight>
                  {formatVolumeLabel(aggregatedData?.volume ?? -1)}
                </S.CellTotalRight>
                <S.CellTotalRight>
                  {modalityPOP
                    ? formatTable(aggregatedData?.balance ?? -1)
                    : "n/a"}
                </S.CellTotalRight>
              </React.Fragment>
            ))}
        </S.GridWrapper>
      )}
    </WidgetWrapper>
  )
}

const formatter = Intl.NumberFormat("en", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
})

const cryptoFormatter = Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
})

export const formatTable = (value: number) => formatter.format(value)

export const formatVolumeLabel = (volume: number) =>
  cryptoFormatter.format(volume) + " BTC"

const formatterDeals = Intl.NumberFormat("en", {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
})
export const formatDeals = (value: number) => formatterDeals.format(value)
